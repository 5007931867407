/*
  Firmware String Parser
  v1.2 - 2023-07-31 - James Lowe
*/

const LATEST_MAJOR_VERSION = 19

function parseFirmwareString(firmwareVersionString, { isStaff = false } = {}) {
  //////////////
  // defaults //
  //////////////

  const parsed = {
    version: {
      original: firmwareVersionString,
      version: null,
      major: null,
      minor: null,
      revision: null,
      rc: null
    },
    dormantModeAvailable: false,
    rainbowLedModeAvailable: false
  }

  if (!firmwareVersionString) return parsed
  if (firmwareVersionString === '') return parsed
  if (firmwareVersionString === undefined || firmwareVersionString === 'undefined') return parsed
  if (firmwareVersionString === null || firmwareVersionString === 'null') return parsed

  /////////////////////////////////
  // rev16 format exception rule //
  /////////////////////////////////

  if (firmwareVersionString.includes('rev16')) {
    console.error('ERROR: rev16 detected')
    parsed.version.major = 16
    parsed.version.minor = 0
    parsed.version.revision = 0
    parsed.dormantModeAvailable = false
    return parsed
  }

  //////////////////////////////
  // modern versioning format //
  //////////////////////////////

  // .version.version (#.#.#)
  parsed.version.version = firmwareVersionString
  if (firmwareVersionString.indexOf('-') > -1) parsed.version.version = firmwareVersionString.substr(0, firmwareVersionString.indexOf('-'))

  const versionParts = parsed.version.version.split('.')
  // .version.major
  if (!isNaN(versionParts[0])) parsed.version.major = parseInt(versionParts[0], 10)
  // .version.minor
  if (!isNaN(versionParts[1])) parsed.version.minor = parseInt(versionParts[1], 10)
  // .version.revision
  if (!isNaN(versionParts[2])) parsed.version.revision = parseInt(versionParts[2], 10)

  // .version.rc
  if (firmwareVersionString.substr(parsed.version.version.length, 3) === '-rc')
    parsed.version.rc = firmwareVersionString.substr(parsed.version.version.length + 1)

  /////////////////////////////////
  // determine firmware features //
  /////////////////////////////////

  const { major, minor, revision, rc } = parsed.version

  // always hide dormant mode when parsing firmware string.
  //   dormant mode is now enabled only by `P3Contract`.`showDormantOnPortal` === 1
  parsed.dormantModeAvailable = false

  // warn future major versions
  if (major > LATEST_MAJOR_VERSION) {
    console.error(`ERROR: No rules for firmware version string "${firmwareVersionString}"`)
    return parsed
  }

  if (major === 18) {
    if (minor === 0 && revision >= 2) {
      parsed.dormantModeAvailable = isStaff // only make dormant mode available if logged in as staff
    }
    if (minor > 0) {
      parsed.dormantModeAvailable = isStaff // only make dormant mode available if logged in as staff
    }
  }

  if (major === 19) {
    parsed.dormantModeAvailable = isStaff // only make dormant mode available if logged in as staff

    parsed.rainbowLedModeAvailable = true

    // TODO: TEMPORARY:
    if (rc === 'rc6.0-clustering' || isStaff) {
      parsed.clusteringCompatible = true
    }
  }

  if (major === 0) {
    // New DC firmware
    parsed.dormantModeAvailable = isStaff // only make dormant mode available if logged in as staff

    parsed.rainbowLedModeAvailable = true
  }

  return parsed
}

export { parseFirmwareString }
// module.exports = { parseFirmwareString }
