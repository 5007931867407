import React, { useState } from 'react'
import { Modal, Button, Header, Segment, Icon } from 'semantic-ui-react'
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Preferences.css'

function Preferences() {
  const location = useLocation()
  const navigate = useNavigate()

  const basePath = '/home/account/preferences'

  return (
    <>
      <Modal
        id="Preferences"
        size={location.pathname !== basePath && 'large'}
        open
        centered={false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <ToastContainer
          containerId="toastPreferences"
          position="top-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={true}
          rtl={false}
          pauseOnFocusLoss={false}
          closeButton={false}
          theme="dark"
          limit={1}
        />
        {location.pathname === basePath ? (
          <>
            <Modal.Header>
              <Icon name="cogs" />
              &nbsp;&nbsp;&nbsp;Preferences
            </Modal.Header>
            <Modal.Content className="Preferences">
              <Segment.Group padded>
                <Segment padded onClick={() => navigate(basePath + '/account-details')}>
                  <Header>Account Details</Header>
                  <p>Change your name, address, phone number, etc ...</p>
                  <Icon name="chevron right" size="big" />
                </Segment>
                <Segment padded onClick={() => navigate(basePath + '/gateway')}>
                  <Header>Gateway Settings</Header>
                  <p>We scan for Met Office severe weather alerts containing a power outage warning.</p>
                  <Icon name="chevron right" size="big" />
                </Segment>
              </Segment.Group>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary onClick={() => navigate(-1)}>
                Close
              </Button>
            </Modal.Actions>
          </>
        ) : (
          <>
            <Outlet />
          </>
        )}
      </Modal>
    </>
  )
}

export { Preferences }
