import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { authenticator } from '../../authenticator'
import { debugMessage, friendlyFormat, parseModelCode } from '../../common'
import { ajax } from '../../ajax'
import { Chart } from '../Chart/Chart'
import { Segment, Message, Loader, Divider, Header, Icon, Button, Modal, Popup } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getEnglishText } from '../../dictionary'

import ScheduleRibbons from '../common/ScheduleRibbons/ScheduleRibbons'
import { sumChartData } from '../../lib/groupUnitData'

import './Charts.css'

// colors copied from 'src\semantic-ui\powervault-v1\globals\site.variables' :
const PV_SOLAR_COLOR = 'rgba(251, 176, 59, 0.9)'
const PV_BATTERY_COLOR = 'rgba(53, 113, 177, 0.9)'
const PV_GRID_CHARGING_COLOR = 'rgba(102, 45, 145, 0.9)'
const PV_GRID_COLOR = 'rgba(193, 39, 45, 0.9)'
const PV_CONSUMED_COLOR = 'rgba(251, 176, 59, 0.9)'
const PV_STORED_COLOR = 'rgba(53, 113, 177, 0.9)'
const PV_EXPORTED_COLOR = 'rgba(193, 39, 45, 0.9)'
const PV_SOC_COLOR = 'rgba(0, 157, 156, 0.7)'

const powerUsedChartOptions = {
  type: 'area',
  height: 200,
  xAxis: {},
  yAxis: [
    { width: 25, label: 'kW', yAxisId: 'left' },
    { width: 25, label: '%', yAxisId: 'right', domain: [0, 100], hide: true }
  ],
  dataSets: [
    {
      key: 'Solar',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_SOLAR_COLOR,
      fillColor: PV_SOLAR_COLOR
    },
    {
      key: 'Battery',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_BATTERY_COLOR,
      fillColor: PV_BATTERY_COLOR
    },
    {
      key: 'Grid Charging',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_GRID_CHARGING_COLOR,
      fillColor: PV_GRID_CHARGING_COLOR
    },
    {
      key: 'Grid',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_GRID_COLOR,
      fillColor: PV_GRID_COLOR
    },
    {
      key: 'SoC',
      yAxisId: 'right',
      displayAs: 'line',
      unit: '%',
      type: 'monotone',
      strokeColor: PV_SOC_COLOR,
      legendColor: PV_SOC_COLOR,
      fillColor: PV_SOC_COLOR
    }
  ],
  tooltip: true
}

const powerGeneratedChartOptions = {
  type: 'area',
  height: 200,
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  xAxis: {},
  yAxis: [{ width: 35, label: 'kW', yAxisId: 'left' }],
  dataSets: [
    {
      key: 'Consumed',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_CONSUMED_COLOR,
      fillColor: PV_CONSUMED_COLOR
    },
    {
      key: 'Stored',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_STORED_COLOR,
      fillColor: PV_STORED_COLOR
    },
    {
      key: 'Exported',
      yAxisId: 'left',
      displayAs: 'area',
      unit: 'kW',
      type: 'monotone',
      strokeColor: 'rgba(0,0,0,0)',
      legendColor: PV_EXPORTED_COLOR,
      fillColor: PV_EXPORTED_COLOR
    }
  ],
  tooltip: true
}

const accessoryClampChartOptions = {
  type: 'area',
  height: 200,
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  xAxis: {},
  yAxis: [{ width: 35, label: 'kW', yAxisId: 'left' }],
  dataSets: [{ key: 'Accessory Clamp 1', yAxisId: 'left', displayAs: 'area', unit: 'kW', type: 'monotone', stroke: 'rgba(0,0,0,1)', fillColor: '#df8aff' }],
  tooltip: true
}

let smartHomeChartOptions = {
  type: 'area',
  height: 200,
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  xAxis: {},
  yAxis: [{ width: 35, label: 'kW', yAxisId: 'left' }],
  dataSets: [], // generated below, before chart render
  tooltip: true
}

let refreshHandle = null
let lastRequestTime = 0

function Charts({ units, handleDateChange, isInstaller, isStaff, scheduleNudge }) {
  const datePickerRef = useRef()

  const [error, setError] = useState(null)
  const [warning, setWarning] = useState(units && units[0] ? null : 'No unit ID(s)')
  const [isLoaded, setIsLoaded] = useState(false)
  const [busyElement, setBusyElement] = useState(null)
  const [dateLocal, setDateLocal] = useState(moment().startOf('day'))
  const [data, setData] = useState([])
  const [showChartedSoc, setShowChartedSoc] = useState(false)

  const [nowRowIndex, setNowRowIndex] = useState(null)

  const [_scheduleNudge, setScheduleNudge] = useState()

  const [smartHomeDeviceKeys, setSmartHomeDeviceKeys] = useState([])

  const [clusters, setClusters] = useState([])

  // const refreshInterval = 60000
  const refreshInterval = 1000 * 60 * 5

  useEffect(() => {
    debugMessage('Mounted', 'Charts')
    fetchData()
    return () => {
      clearTimeout(refreshHandle)
    }
  }, [])

  useEffect(() => {
    debugMessage('Schedule nudge', 'Charts')
    setScheduleNudge(scheduleNudge)
  }, [scheduleNudge])

  useEffect(() => {
    debugMessage('Updated', 'Charts')
    setIsLoaded(false)
    fetchData()
  }, [units])

  useEffect(() => {
    debugMessage('Updated', 'Charts')
    setNowRowIndex(null)
    fetchData()
  }, [dateLocal])

  useEffect(() => {
    const greyscaleMin = 0
    const greyscaleMax = 190
    const greyscaleSteps = smartHomeDeviceKeys.length

    smartHomeChartOptions.dataSets = smartHomeDeviceKeys.map((key, index) => {
      const greyscale = ((greyscaleMax - greyscaleMin) / greyscaleSteps) * (index + 1)
      return {
        key,
        unit: 'kW',
        type: 'monotone',
        strokeColor: `rgb(0, ${greyscale}, 0)`,
        fillColor: `rgb(0, ${greyscale}, 0)`
      }
    })
  }, [data])

  function fetchData() {
    clearTimeout(refreshHandle)
    setWarning()

    if (!units || !units[0]) {
      setWarning(getEnglishText('charts : no-unit-id'))
      return
    }

    const idToken = authenticator.getToken()

    let params = {
      units,
      startDateLocal: dateLocal,
      endDateLocal: dateLocal.clone().add(24, 'hours').subtract(1, 'millisecond')
    }

    lastRequestTime = new Date().getTime()
    let thisRequestTime = new Date().getTime()

    const lastDateLocal = dateLocal.format() // used to avoid calling handleDateChange() below

    // if any selected units can display charted SoC then enable charted SoC for all units on chart
    const chartableSocUnits = units.filter((u) => parseModelCode(u.model).displaySocOnChart)
    setShowChartedSoc(chartableSocUnits.length > 0)

    ajax
      .fetchChartData(idToken, { ...params })
      .then(({ clusters }) => {
        if (lastRequestTime !== thisRequestTime) {
          debugMessage('Data from old request, discarding', 'Charts')
          return
        }

        let chartData = null
        // sum chart data if more than one unit
        if (clusters.length > 1) {
          chartData = sumChartData(clusters)
          setClusters(clusters)
        } else {
          chartData = clusters[0]
          setClusters([])
        }

        let foundSmartHomeDeviceKeys = []

        if (chartData && chartData.series) {
          // translate keys for chart use
          function unitToChartData(chartData) {
            return chartData.series.map((row, index) => {
              const {
                solarConsumedByHome,
                batteryInputFromGrid,
                batteryInputFromSolar,
                batteryOutputConsumedByHome,
                gridConsumedByHome,
                solarExported,
                missingData,
                noInternet,
                gridClampDisconnected,
                hardwareId,
                hardwareIdChanged,
                accessoryClamp1,
                smartHomeDevices: smartHomeDevicesOriginal,
                instant_soc,
                isNow
              } = row || {}

              let smartHomeDevices = {}
              for (let key in smartHomeDevicesOriginal) {
                const renamedKey = key.replace('SmartHome_Device_', '')
                smartHomeDevices[renamedKey] = friendlyFormat(renamedKey.includes('_mW') ? 'generic_mW' : null, smartHomeDevicesOriginal[key])[0]
                if (!foundSmartHomeDeviceKeys.includes(renamedKey)) foundSmartHomeDeviceKeys.push(renamedKey)
              }

              if (isNow) {
                setNowRowIndex(index)
              }

              return {
                name: moment(row.time).format('HH:mm'),
                // SoC
                SoC: chartableSocUnits.length > 0 ? friendlyFormat('instant_soc', instant_soc)[0] : undefined,
                // Power Used
                Solar: friendlyFormat('chart_solar_consumed_by_home', solarConsumedByHome)[0],
                Battery: friendlyFormat('chart_battery_output_consumed_by_home', batteryOutputConsumedByHome)[0],
                'Grid Charging': friendlyFormat('chart_battery_input_from_grid', batteryInputFromGrid)[0],
                Grid: friendlyFormat('chart_grid_consumed_by_home', gridConsumedByHome)[0],
                // Power Generated
                Consumed: friendlyFormat('chart_solar_consumed_by_home', solarConsumedByHome)[0],
                Stored: friendlyFormat('chart_battery_input_from_solar', batteryInputFromSolar)[0],
                Exported: friendlyFormat('chart_solar_exported', solarExported)[0],
                // Accessory Clamps
                'Accessory Clamp 1': friendlyFormat('chart_accessory_clamp', accessoryClamp1)[0],
                // Smart Home Devices
                ...smartHomeDevices,
                // Misc
                isNow: row.isNow,
                missingData,
                noInternet: noInternet && !instant_soc ? true : false, // only show `noInternet` if data is SoC missing as well
                gridClampDisconnected,
                hardwareId,
                hardwareIdChanged
              }
            })
          }

          chartData = unitToChartData(chartData)
        }

        setSmartHomeDeviceKeys(foundSmartHomeDeviceKeys)

        // only call handleDateChange() if date has actually changed
        if (lastDateLocal !== dateLocal.format()) handleDateChange(dateLocal)

        if (!data) setWarning('No data')

        setBusyElement(null)
        setDateLocal(dateLocal)
        setData(chartData)
        setIsLoaded(true)
      })
      .catch((error) => {
        debugMessage(error)
        setError(error)
      })

    clearTimeout(refreshHandle) // clear existing refresh handle
    refreshHandle = setTimeout(() => fetchData(), refreshInterval)
  }

  function handleTodayClick(e) {
    if (busyElement) return

    const today = moment().startOf('day')

    setBusyElement('todayButton')
    setDateLocal(today)
    handleDateChange(today)
  }

  function handleStepDayClick(e, step) {
    if (busyElement) return

    const today = moment().startOf('day')
    const newDateLocal = moment(dateLocal).add(step, 'days')

    if (!moment(newDateLocal).isAfter(today)) {
      setBusyElement(step > 0 ? 'nextDayButton' : 'prevDayButton')
      setDateLocal(newDateLocal)
      handleDateChange(newDateLocal)
    }
  }

  function handleCalendarClick(e) {
    if (busyElement) return
    datePickerRef.current.setOpen(true)
  }

  function handleCalendarChange(selectedDateLocal) {
    const today = moment().startOf('day')

    if (!moment(selectedDateLocal).isAfter(today)) {
      setBusyElement('calendarButton')
      setDateLocal(moment(selectedDateLocal))
      handleDateChange(moment(selectedDateLocal))
    }
  }

  function nonZeroDataExists(key) {
    for (let i in data) {
      let value = data[i][key]
      value = parseFloat(value, 10)
      if (value !== 0 && !isNaN(value)) return true
    }
    return false
  }

  function smartHomeDataExists() {
    return smartHomeDeviceKeys.length > 0
  }

  const isToday = moment().startOf('day').isSame(dateLocal)

  const powerUsedChartOptionsFiltered = showChartedSoc
    ? powerUsedChartOptions
    : {
        ...powerUsedChartOptions,
        yAxis: powerUsedChartOptions.yAxis.filter((y) => y.yAxisId !== 'right'),
        dataSets: powerUsedChartOptions.dataSets.filter((ds) => ds.key !== 'SoC')
      }

  return (
    <Segment id="charts">
      <React.Fragment>
        <Header as="h2">
          <Icon name="chart bar outline" color="teal" />
          <Header.Content>
            {getEnglishText('charts : title')}&nbsp;&nbsp;
            {isLoaded && units.length > 1 && (
              <span className="clusters-desc" style={{ fontSize: clusters.length > 1 ? '75%' : '100%' }}>
                <>
                  ({units.length} units
                  {clusters.length > 1 && <>{`, in ${clusters.length} clusters`}</>})&nbsp;&nbsp;
                </>
              </span>
            )}
            <span style={{ display: 'inline-block' }} className="date">
              {moment(dateLocal).format('Do MMMM YYYY')}
            </span>
            <Header.Subheader>{getEnglishText('charts : subtitle')}</Header.Subheader>
          </Header.Content>
        </Header>
        <div className="date-selector">
          <DatePicker
            portalId="root-portal"
            ref={datePickerRef}
            onChange={handleCalendarChange}
            // openToDate={new Date('1993/09/28')}
            withPortal
            todayButton="TODAY"
            // onCalendarClose={handleCalendarClose}
            // onCalendarOpen={handleCalendarOpen}
          />

          <Button className="today-button" primary onClick={handleTodayClick} loading={busyElement === 'todayButton'} disabled={isToday}>
            Today
          </Button>

          <Popup
            inverted
            trigger={
              <Button className="date-picker-button" basic icon onClick={handleCalendarClick} loading={busyElement === 'calendarButton'}>
                <Icon color="teal" name={busyElement === 'calendarButton' ? '' : 'calendar alternate outline'} />
              </Button>
            }
            content="Pick a day"
          />

          <Popup
            inverted
            trigger={
              <Button icon onClick={(e) => handleStepDayClick(e, -1)} loading={busyElement === 'prevDayButton'}>
                <Icon name="chevron left" />
              </Button>
            }
            content="Previous day"
          />

          <Popup
            inverted
            trigger={
              <Button icon onClick={(e) => handleStepDayClick(e, +1)} loading={busyElement === 'nextDayButton'} disabled={isToday}>
                <Icon name="chevron right" />
              </Button>
            }
            content="Next day"
          />
        </div>
        <Divider hidden className="mobile-hidden" />
        <Divider hidden />
      </React.Fragment>

      {!isLoaded && <Loader active inline="centered" />}

      {error && (
        <Message negative>
          <p>Error: {error.message}</p>
        </Message>
      )}

      {warning && (
        <Message warning>
          <p>{warning}</p>
        </Message>
      )}

      {isLoaded && !error && !warning && (
        <div>
          <div className="container">
            <Header as="h3">Power Used</Header>

            <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={powerUsedChartOptionsFiltered} />
            <Modal size="large" trigger={<Button basic className="fullscreen" size="mini" icon="expand"></Button>} dimmer="blurring">
              <Modal.Header>
                <Icon name="chart bar outline" />
                &nbsp;&nbsp;&nbsp;Power Used
              </Modal.Header>
              <Modal.Content>
                <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={{ ...powerUsedChartOptionsFiltered, height: 500 }} />
              </Modal.Content>
            </Modal>
          </div>

          <Divider hidden />

          <div className="container">
            <Header as="h3">Power Generated</Header>

            <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={powerGeneratedChartOptions} />
            <Modal size="large" trigger={<Button basic className="fullscreen" size="mini" icon="expand"></Button>} dimmer="blurring">
              <Modal.Header>
                <Icon name="chart bar outline" />
                &nbsp;&nbsp;&nbsp;Power Generated
              </Modal.Header>
              <Modal.Content>
                <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={{ ...powerGeneratedChartOptions, height: 500 }} />
              </Modal.Content>
            </Modal>
          </div>

          {isToday && !clusters.length && (
            <div className="container operating-state">
              <Header as="h3" style={{ marginTop: 10 }}>
                Operating State
              </Header>
              <ScheduleRibbons
                unit={units[0]}
                showEmptyDays={false}
                nowCursorStyle={{ borderLeft: '1px dashed black' }}
                showScheduleTypes={['ffr', 'smartstor', 'customer']}
                hideOverriddenScheduleTypes="when-no-gaps"
                showDays={[moment().format('dddd').toLowerCase()]} // "monday", "tuesday", ...etc
                showScheduleNames={true}
                showScale={true}
                showLegend={true}
                showDayNames={false}
                customScaleSpacingHandler={(containerWidth) => {
                  switch (true) {
                    case containerWidth > 979:
                      return [0, 0] // [start, skip]
                    case containerWidth > 489:
                      return [1, 2] // [start, skip]
                    case containerWidth > 357:
                      return [2, 3] // [start, skip]
                    case containerWidth > 326:
                      return [1, 3] // [start, skip]
                    case containerWidth > 287:
                      return [3, 4] // [start, skip]
                    case containerWidth > 244:
                      return [2, 4] // [start, skip]
                    case containerWidth > 238:
                      return [3, 4] // [start, skip]
                    case containerWidth > 204:
                      return [2, 4] // [start, skip]
                    default:
                      return [0, 0] // [start, skip]
                  }
                }}
                scheduleNudge={_scheduleNudge}
              />
            </div>
          )}

          {isStaff && nonZeroDataExists('Accessory Clamp 1') && (
            <>
              <Divider hidden />

              <div className="container">
                <Header as="h3">Accessory Clamps</Header>

                <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={accessoryClampChartOptions} />
                <Modal size="large" trigger={<Button basic className="fullscreen" size="mini" icon="expand"></Button>} dimmer="blurring">
                  <Modal.Header>
                    <Icon name="chart bar outline" />
                    &nbsp;&nbsp;&nbsp;Accessory Clamps
                  </Modal.Header>
                  <Modal.Content>
                    <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={{ ...accessoryClampChartOptions, height: 500 }} />
                  </Modal.Content>
                </Modal>
              </div>
            </>
          )}

          {isStaff && smartHomeDataExists() && (
            <>
              <Divider hidden />

              <div className="container">
                <Header as="h3">Smart Home Devices</Header>

                <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={smartHomeChartOptions} />
                <Modal size="large" trigger={<Button basic className="fullscreen" size="mini" icon="expand"></Button>} dimmer="blurring">
                  <Modal.Header>
                    <Icon name="chart bar outline" />
                    &nbsp;&nbsp;&nbsp;Smart Home Devices
                  </Modal.Header>
                  <Modal.Content>
                    <Chart nowRowIndex={nowRowIndex} chartData={data} chartOptions={{ ...smartHomeChartOptions, height: 500 }} />
                  </Modal.Content>
                </Modal>
              </div>
            </>
          )}
        </div>
      )}
    </Segment>
  )
}

export { Charts }
