import React, { useState } from 'react'
import { Modal, Button, Menu, Grid } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'

// import { FAQ } from "./pages/HelpFAQ"
import { HelpSmartSTOR } from './pages/HelpSmartSTOR'
import { HelpPowervaultOffline } from './pages/HelpPowervaultOffline'
import { ContactSupport } from './pages/ContactSupport'

import { getEnglishText } from '../../dictionary'

import './Help.css'

function Help() {
  const navigate = useNavigate()

  const [activeListItem, setActiveListItem] = useState('contact-support')

  function handleListItemClick(e, item) {
    setActiveListItem(item.id)
  }

  return (
    <>
      <Modal
        id="Help"
        open
        centered={false}
        dimmer="blurring"
        onClose={(e) => {
          e.stopPropagation()
          navigate('/home')
        }}
      >
        <Modal.Header>{getEnglishText('help : title')}</Modal.Header>
        <Modal.Content>
          <Grid stackable>
            <Grid.Column width={4}>
              <Menu fluid secondary vertical>
                {/* <Menu.Item name="FAQ" id="faq" active={activeListItem === 'faq'} onClick={handleListItemClick} /> */}
                <Menu.Item
                  name="Contact Us"
                  content="Contact Us"
                  id="contact-support"
                  active={activeListItem === 'contact-support'}
                  onClick={handleListItemClick}
                />
                <Menu.Item
                  name="SMARTSTOR&trade;"
                  content="SMARTSTOR&trade;"
                  id="smartstor"
                  active={activeListItem === 'smartstor'}
                  onClick={handleListItemClick}
                />
                <Menu.Item
                  name="My Powervault is offline"
                  id="powervault-offline"
                  active={activeListItem === 'powervault-offline'}
                  onClick={handleListItemClick}
                />
              </Menu>
            </Grid.Column>
            <Grid.Column stretched width={12}>
              <div className="content">
                {activeListItem === 'contact-support' && <ContactSupport />}
                {activeListItem === 'powervault-offline' && <HelpPowervaultOffline />}
                {/*{activeListItem === "faq" && <FAQ />} */}
                {activeListItem === 'smartstor' && <HelpSmartSTOR />}
              </div>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary as={Link} to="/home">
            {getEnglishText('common : close')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export { Help }
