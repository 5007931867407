import React from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { getEnglishText } from '../../dictionary'

function UnknownEmail() {
  return (
    <div id="UnknownEmail">
      <Modal size="tiny" open={true} centered={false} dimmer="blurring">
        <Modal.Header>
          <Icon name="exclamation" />
          &nbsp;&nbsp;{getEnglishText('unknown-email : modal-title')}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>{getEnglishText('unknown-email : modal-body')}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button as={Link} to={'/logout'} primary>
            {getEnglishText('common : sign-out')}
          </Button>
          <Button secondary basic href="mailto:service@powervault.co.uk?subject=Enquiry">
            <Icon name="mail" /> {getEnglishText('common : contact-us')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export { UnknownEmail }
