import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider, Outlet, redirect } from 'react-router-dom'
import { debugMessage } from './common'
import { versionChecker } from './versionChecker'
import { authenticator } from './authenticator'

import AppContextProvider from './AppContextProvider'

import { Background } from './components/Background/Background'
import { Home } from './components/Home/Home'
import { Login } from './Login'
import { Logout } from './Logout'
import { Action } from './Action'
import { QuickStart } from './components/QuickStart/QuickStart'
import { ReleaseNotes } from './components/ReleaseNotes/ReleaseNotes'
import { Help } from './components/Help/Help'
import { Preferences } from './components/Preferences/Preferences'
import { GatewaySettings } from './components/Preferences/GatewaySettings'
import { CustomerAccountSettings } from './components/Preferences/CustomerAccountSettings'
import { ErrorPage } from './components/ErrorPage/ErrorPage'

// import ThemingLayout from './ThemingLayout'

import 'semantic-ui-less/semantic.less'
import './App.css'

const router = createBrowserRouter([
  {
    path: '/',
    loader: async ({ request }) => {
      const isAuthenticated = await authenticator.isAuthenticated()

      if (request.url.includes('/login')) return true
      if (request.url.includes('/action')) return true
      if (request.url.includes('/quick-start')) return true

      if (isAuthenticated && request.url.includes('/logout')) return true

      const latestVersion = await versionChecker()
      if (!latestVersion) {
        debugMessage('Old version, redirecting to /login', 'router')
        return redirect('/login')
      }

      if (!isAuthenticated) {
        debugMessage('Not authenticated, authenticator.signIn()', 'router')
        await authenticator.signIn()
      }

      if (request.url.includes('/home')) {
        // check if `action` exists in localstorage
        if (window.localStorage.getItem('action')) return redirect('/action')

        // allow navigate to /home
        return true
      }

      debugMessage('Redirecting to /home', 'router')
      return redirect('/home')
    },
    errorElement: <ErrorPage />,
    element: (
      <AppContextProvider>
        <Layout />
      </AppContextProvider>
    ),
    children: [
      {
        path: 'home',
        element: <Home />,
        children: [
          {
            path: 'account',
            element: null, // handled in Home component
            children: [
              {
                path: 'release-notes',
                element: <ReleaseNotes />
              },
              {
                path: 'preferences',
                element: <Preferences />,
                children: [
                  {
                    path: 'account-details',
                    element: <CustomerAccountSettings />
                  },
                  {
                    path: 'gateway',
                    element: <GatewaySettings />
                  }
                ]
              }
            ]
          },
          {
            path: 'help',
            element: <Help />
          },
          {
            path: 'preferences',
            element: <Preferences />,
            children: [
              {
                path: 'gateway',
                element: <GatewaySettings />
              }
            ]
          }
        ]
      },
      {
        path: 'quick-start',
        element: <QuickStart />
      },
      {
        path: 'action',
        element: <Action />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'logout',
        element: <Logout />
      }
    ]
  }
])

export default function App() {
  useEffect(() => {
    debugMessage('Mounted', 'App')
    document.body.classList.add('initial-load')
    checkAuth()
  }, [])

  function checkAuth() {
    try {
      if (!authenticator.isAuthenticated()) authenticator.signIn()
    } catch (e) {}
  }

  return <RouterProvider router={router} />
}

function Layout() {
  return (
    <div className="App">
      <Background />
      <Outlet />
    </div>
  )
}
