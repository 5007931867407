import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button, Header, Checkbox, AccordionTitle, AccordionContent, Accordion, Icon, Divider, Segment, Message } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import { ajax } from '../../ajax'
import { readLocalStorageUnitData } from '../../common'
import { toast } from 'react-toastify'

import './CustomerAccountSettings.css'

///////////
// TOAST //
///////////

let toastTimeoutHandle = null
const toastLoading = () => {
  clearTimeout(toastTimeoutHandle)
  toastTimeoutHandle = setTimeout(() => {
    toast('Loading ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  }, 1000)
}
const toastLoaded = () => {
  clearTimeout(toastTimeoutHandle)
  toast.dismiss({ id: 'myToast' })
}
const toastSaving = () => {
  clearTimeout(toastTimeoutHandle)
  toast('Saving ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Saving ...', type: 'info', autoClose: false })
}
const toastSaved = () => {
  clearTimeout(toastTimeoutHandle)
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Preferences saved.', type: 'success', autoClose: 3000 })
}

///////////
///////////
///////////

function CustomerAccountSettings() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  const [gatewaySettings, setCustomerAccountSettings] = useState({
    severeWeatherAlertsEnabled: false,
    autoChargingEnabled: false,
    sendSmsNotifications: false
  })

  const { accountId, configId } = readLocalStorageUnitData()[0]

  useEffect(() => {
    fetchCustomerAccountSettings()
    return () => {}
  }, [])

  async function fetchCustomerAccountSettings() {
    setLoading(true)
    toastLoading()
    try {
      const preferences = await ajax.getPreferences({ configId })
      setCustomerAccountSettings(preferences.gateway)
      setLoading(false)
      toastLoaded()
    } catch (error) {
      console.error(error)
    }
  }

  async function postCustomerAccountSettings(gatewaySettings) {
    try {
      setSaving(true)
      toastSaving()
      await ajax.postPreferences({ configId }, { gateway: gatewaySettings })
      setSaving(false)
      toastSaved()
    } catch (error) {
      console.error(error)
    }
  }

  const CustomCheckbox = ({ id, label, disabled = false }) => (
    <Checkbox
      toggle
      disabled={disabled}
      label={label}
      checked={gatewaySettings?.[id]}
      id={id}
      onChange={async (e, { id, checked }) => {
        if (id === 'severeWeatherAlertsEnabled' && checked && gatewaySettings?.severeWeatherAlertsEnabled === undefined) {
          // first time user has enabled, so also enable Auto-Force Charge
          setCustomerAccountSettings({ ...gatewaySettings, [id]: checked, autoChargingEnabled: true })
          await postCustomerAccountSettings({ ...gatewaySettings, [id]: checked, autoChargingEnabled: true })
        } else {
          setCustomerAccountSettings({ ...gatewaySettings, [id]: checked })
          await postCustomerAccountSettings({ ...gatewaySettings, [id]: checked })
        }
      }}
    />
  )

  return (
    <>
      <Modal.Header>
        <Icon name="cogs" />
        &nbsp;&nbsp;&nbsp;Account Details
      </Modal.Header>
      <Modal.Content className="CustomerAccountSettings" scrolling>
        <Segment basic vertical loading={loading}>
          <Header>Account Contact Details</Header>

          <p>First Name</p>
          <p>Last Name</p>
          <p>Mobile phone number</p>
          <p>Address line 1</p>
          <p>City/Town</p>
          <p>Postcode</p>

          <Divider hidden />
          <Divider />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading || saving} secondary onClick={() => navigate(-1)}>
          Done
        </Button>
      </Modal.Actions>
    </>
  )
}

export { CustomerAccountSettings }
