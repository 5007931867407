import pckg from '../package.json'
import { cleanup, debugMessage } from './common'
import { ajax } from './ajax.js'

async function versionChecker() {
  // return true
  const { version: clientVersion } = pckg
  debugMessage('Checking versions', 'versionChecker')

  const versions = await ajax.fetchVersion({ clientVersion })

  const correctLocalVersion = checkVersions(versions)
  if (correctLocalVersion) {
    debugMessage('Front-end version is up to date', 'versionChecker')
    return true
  }

  debugMessage('Front-end version is old, refreshing now...', 'versionChecker')
  setTimeout(() => cleanup({ initiator: 'versionChecker' }), 1000)
  return false
}

function parseVersionString(versionString) {
  const parts = versionString.split('.')
  try {
    return [parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2], 10)]
  } catch (e) {
    console.error(`Could not parse version string ${versionString}`)
    return [0, 0, 0]
  }
}

// return -1 if this version if below what the cloud expects
function compareVersionStrings(actualVersion, targetVersion) {
  const [aMajor, aMinor, aRevision] = parseVersionString(actualVersion)
  const [tMajor, tMinor, tRevision] = parseVersionString(targetVersion)

  if (aMajor < tMajor) return -1
  if (aMajor > tMajor) return 1

  if (aMinor < tMinor) return -1
  if (aMinor > tMinor) return 1

  if (aRevision < tRevision) return -1
  if (aRevision > tRevision) return 1

  return 0
}

function checkVersions(data) {
  const { version: frontendVersionLocal } = pckg
  const { versions } = data || {}
  const { frontendVersion } = versions || {}

  if (!frontendVersion || !frontendVersionLocal) return true

  const versionComparison = compareVersionStrings(frontendVersionLocal, frontendVersion)

  if (versionComparison < 0) {
    debugMessage(`Portal version ${frontendVersionLocal} is behind API ${frontendVersion}`, 'versionChecker')
    return false
  } else if (versionComparison > 0) {
    debugMessage(`Portal version ${frontendVersionLocal} is ahead of API ${frontendVersion}`, 'versionChecker')
    return true
  } else {
    debugMessage(`Portal version ${frontendVersionLocal} matches API ${frontendVersion}`, 'versionChecker')
    return true
  }
}

export { versionChecker }
