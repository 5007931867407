import React, { useEffect, useState } from 'react'
import { Grid, Button, Loader, Header, Icon, Segment, Dimmer, Dropdown, Input, Message, Modal } from 'semantic-ui-react'
import moment from 'moment'
import { authenticator } from '../../authenticator'
import { parseFirmwareString, friendlyNameFromScheduleState } from '../../common'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import ScheduleRibbons from '../common/ScheduleRibbons/ScheduleRibbons'
import schedulePresets from '../Schedule/schedulePresets'

import './ScheduleEditor.css'

function ScheduleEditor(props) {
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const daysOfWeekTitleCase = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const today = moment().format('dddd').toLowerCase()

  const idToken = authenticator.getToken()
  const { unit, clusters, onSaveSchedule, onClose, isStaff, device } = props
  const { hardwareId, showDormantOnPortal } = unit

  // showDormantOnPortal = 1 if set manually on Contract
  //   otherwise we check the firmware string to determine if dormant mode is available
  const dormantModeAvailable = showDormantOnPortal === 1 ? true : parseFirmwareString(unit.firmwareVersion, { isStaff }).dormantModeAvailable

  const [selectedDay, setSelectedDay] = useState(today)
  const [changedDays, setChangedDays] = useState([])

  const [showDaysInRibbon, setShowDaysInRibbon] = useState([today])

  const [selectedSlotId, setSelectedSlotId] = useState(null)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [slotRemoved, setSlotRemoved] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [copiedToDays, setCopiedToDays] = useState(false)
  const [dayCleared, setDayCleared] = useState(false)

  const [mainModelOpen, setMainModelOpen] = useState(true)
  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [slotConflict, setSlotConflict] = useState(false)
  const [saveFailed, setSaveFailed] = useState(false)
  const [showPresets, setShowPresets] = useState(authenticator.getUserDetails().email.includes('@powervault.co.uk'))

  const [customerSchedule, setCustomerSchedule] = useState(null)
  // const [ffrSchedule, setFfrSchedule] = useState(null)
  // const [smartSchedule, setSmartSchedule] = useState(null)

  const [displayAsCluster, setDisplayAsCluster] = useState(false)

  const [copyToAllDaysPromptOpen, setCopyToAllDaysPromptOpen] = useState(false)
  const [undoBuffer, setUndoBuffer] = useState({})

  useEffect(() => {
    fetchSchedule()
  }, [hardwareId])

  useEffect(() => {
    setDisplayAsCluster(clusters[0] && clusters[0][0] && clusters[0][0].clusterHash)
  }, [clusters])

  function fetchSchedule() {
    ajax
      .fetchUnitSchedules(hardwareId, idToken)
      .then((scheduleData) => {
        setCustomerSchedule(addRandomIds(scheduleData.schedule))
        // setFfrSchedule(scheduleData.ffrSchedule)
        // setSmartSchedule(scheduleData.smartSchedule)
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  async function saveSchedule({ ignoreUnchangedDays = false }) {
    // detect if any days have no schedule so we can ask the user if they want to copy to all days
    if (!areAllDaysChanged() && !ignoreUnchangedDays) {
      // at least one day is empty
      setCopyToAllDaysPromptOpen(true)
      return
    }

    setSaving(true)

    function sendSchedule(hardwareId) {
      return ajax.putUnitSchedule(hardwareId, idToken, 'customer', stripRandomIds(customerSchedule))
    }

    const hardwareIds = clusters[0].map((u) => u.hardwareId)

    if (hardwareIds.length === 1) {
      // single unit
      sendSchedule(hardwareIds[0])
        .then((response) => {
          const { result, errorMessage } = response
          if (result === 'success') {
            setSaved(true)
            setSaving(false)
            setUnsavedChanges(false)
            setErrorMessage(null)
            setSaveFailed(false)
            setCopiedToDays(false)
            setDayCleared(false)
            setSelectedSlotId(null)
            setSlotRemoved(false)
            setCopyToAllDaysPromptOpen(false)
            setChangedDays([])

            if (onSaveSchedule) onSaveSchedule(customerSchedule)
          } else if (errorMessage === 'no-schedule-response-from-unit') {
            setSaving(false)
            setSaveFailed(true)
          } else {
            setSaving(false)
            setErrorMessage(errorMessage)
          }
        })
        .catch((error) => {
          setErrorMessage(error)
        })
    } else {
      // mulitple units
      let errors = []

      for (let hardwareId of hardwareIds) {
        const response = await sendSchedule(hardwareId)
        if (!response.result || response.result !== 'success') {
          errors.push(response)
        }
      }

      if (!errors.length) {
        setSaved(true)
        setSaving(false)
        setUnsavedChanges(false)
        setErrorMessage(null)
        setSaveFailed(false)
        setCopiedToDays(false)
        setDayCleared(false)
        setSelectedSlotId(null)
        setSlotRemoved(false)

        if (onSaveSchedule) onSaveSchedule(customerSchedule)
      } else {
        setErrorMessage(errors[0])
      }
    }
  }

  function areAllDaysChanged() {
    return (
      changedDays.includes('monday') &&
      changedDays.includes('tuesday') &&
      changedDays.includes('wednesday') &&
      changedDays.includes('thursday') &&
      changedDays.includes('friday') &&
      changedDays.includes('saturday') &&
      changedDays.includes('sunday')
    )
  }

  function pad(num, size) {
    var s = num + ''
    while (s.length < size) s = '0' + s
    return s
  }

  function getRandomId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  function stripRandomIds(schedule) {
    function stripIds(slots) {
      return slots.map((slot) => ({
        start: slot.start,
        end: slot.end,
        state: slot.state
      }))
    }
    return {
      monday: stripIds([...schedule.monday]),
      tuesday: stripIds([...schedule.tuesday]),
      wednesday: stripIds([...schedule.wednesday]),
      thursday: stripIds([...schedule.thursday]),
      friday: stripIds([...schedule.friday]),
      saturday: stripIds([...schedule.saturday]),
      sunday: stripIds([...schedule.sunday])
    }
  }

  function addRandomIds(schedule) {
    function addIds(slots) {
      return slots.map((slot) => ({ ...slot, id: getRandomId() }))
    }
    return {
      monday: addIds([...schedule.monday]),
      tuesday: addIds([...schedule.tuesday]),
      wednesday: addIds([...schedule.wednesday]),
      thursday: addIds([...schedule.thursday]),
      friday: addIds([...schedule.friday]),
      saturday: addIds([...schedule.saturday]),
      sunday: addIds([...schedule.sunday])
    }
  }

  function detectConflictsInCurrentDay() {
    let conflict = false

    customerSchedule[selectedDay].forEach((slotA) => {
      const { start: startA, id: idA } = slotA

      customerSchedule[selectedDay].forEach((slotB) => {
        const { start: startB, id: idB } = slotB

        if (idA === idB) return

        if (startA === startB) conflict = startA
      })
    })

    if (conflict) {
      setErrorMessage(getEnglishText('manual-schedule : conflicting-schedule'))
      setSlotConflict(conflict)
    } else {
      setErrorMessage(null)
      setSlotConflict(false)
    }

    return conflict ? true : false
  }

  function updateScheduleObject({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, daysChanged = null }) {
    function orderSlots(slots) {
      return slots.sort((a, b) => {
        const aSeconds = moment(a.start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
        const bSeconds = moment(b.start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
        return aSeconds - bSeconds
      })
    }

    function stretchEndTimes(slots) {
      if (slots.length) {
        for (let i = 0; i < slots.length - 1; i++) {
          slots[i].end = moment(slots[i + 1].start, 'HH:mm:ss')
            .subtract(1, 'second')
            .format('HH:mm:ss')
        }
        slots[slots.length - 1].end = '23:59:59'
      }
    }

    if (daysChanged) {
      if (daysChanged === 'all') daysChanged = [...daysOfWeek]
      if (!Array.isArray(daysChanged)) daysChanged = [daysChanged]

      const changedDaysBuffer = [...changedDays]
      for (let i of daysChanged) if (!changedDaysBuffer.includes(i)) changedDaysBuffer.push(i)

      setChangedDays([...changedDaysBuffer])
    }

    orderSlots(monday)
    orderSlots(tuesday)
    orderSlots(wednesday)
    orderSlots(thursday)
    orderSlots(friday)
    orderSlots(saturday)
    orderSlots(sunday)

    stretchEndTimes(monday)
    stretchEndTimes(tuesday)
    stretchEndTimes(wednesday)
    stretchEndTimes(thursday)
    stretchEndTimes(friday)
    stretchEndTimes(saturday)
    stretchEndTimes(sunday)

    // force correct day order and set
    setCustomerSchedule({
      monday: [...monday],
      tuesday: [...tuesday],
      wednesday: [...wednesday],
      thursday: [...thursday],
      friday: [...friday],
      saturday: [...saturday],
      sunday: [...sunday]
    })

    setSaved(false)
    setSaving(false)
    setErrorMessage(null)
    setSaveFailed(false)
    setCopiedToDays(false)
    setDayCleared(false)
    setSlotRemoved(false)

    setUnsavedChanges(true)
  }

  function loadPreset(preset) {
    updateScheduleObject({ daysChanged: 'all', ...schedulePresets[preset] })
  }

  function createNewItem() {
    function add5MinutesToTime(timeString) {
      let [hours, minutes, seconds] = timeString.split(':')

      hours = parseInt(hours, 10)
      minutes = parseInt(minutes, 10) + 5
      seconds = parseInt(seconds, 10)

      if (minutes > 59) {
        minutes = 0
        hours++
        if (hours > 23) hours = 0
      }

      return [pad(hours, 2), pad(minutes, 2), pad(seconds, 2)].join(':')
    }
    const randomId = getRandomId()

    let slotStart = '00:00:00' // default new slot to midnight

    if (selectedSlotId) {
      // insert after currently selected slot
      slotStart = add5MinutesToTime(customerSchedule[selectedDay].filter((slot) => slot.id === selectedSlotId)[0].start)
    } else if (customerSchedule[selectedDay].length) {
      // insert at end of slots
      slotStart = add5MinutesToTime(customerSchedule[selectedDay][customerSchedule[selectedDay].length - 1].start)
    }

    updateScheduleObject({
      daysChanged: [selectedDay],
      ...customerSchedule,
      [selectedDay]: [
        ...customerSchedule[selectedDay],
        {
          start: slotStart,
          end: slotStart,
          state: 'normal',
          id: randomId
        }
      ]
    })

    setSelectedSlotId(randomId)
    setSaved(false)
    setSlotRemoved(false)
    setErrorMessage(null)
    setSaveFailed(false)
    setCopiedToDays(false)
    setDayCleared(false)
  }

  function DayButtons() {
    return (
      <Segment className="day-buttons" textAlign="center" basic style={{ paddingTop: 0, marginBottom: 0 }}>
        <Button.Group size={device.includes('mobile') ? 'tiny' : null}>
          {daysOfWeek.map((dayName) => {
            const isActive = selectedDay === dayName
            const isChanged = changedDays.includes(dayName)
            return (
              <Button
                disabled={saving}
                key={dayName}
                active={isActive}
                negative={isActive}
                icon
                className={dayName === today ? 'today' : ''}
                onClick={() => {
                  if (!isActive) {
                    if (!detectConflictsInCurrentDay()) {
                      setSelectedSlotId(null)
                      setSlotRemoved(false)
                      setErrorMessage(null)
                      setSaveFailed(false)
                      setCopiedToDays(false)
                      setDayCleared(false)
                      setSelectedDay(dayName)
                      setShowDaysInRibbon([...daysOfWeek])
                    }
                  }
                }}
              >
                {/* "Mon", "Tue", ... */}
                &nbsp;&nbsp;&nbsp;
                <span>{`${dayName.substr(0, 1).toUpperCase()}${dayName.substr(1, 2)}`}</span>
                &nbsp;&nbsp;&nbsp;
                {isChanged && (
                  <>
                    &nbsp;&nbsp;
                    <Icon name="circle" color={isActive ? null : 'blue'} size="tiny" />
                  </>
                )}
              </Button>
            )
          })}
        </Button.Group>
      </Segment>
    )
  }

  function NewItemButton({ primary }) {
    return (
      <Segment
        className={`schedule-slot new-button ${primary && 'primary pv-primary-background-color'}`}
        onClick={saving || !customerSchedule ? null : createNewItem}
        disabled={saving || !customerSchedule}
      >
        <div>
          <Icon name="plus" size="large" />
          &nbsp;&nbsp;&nbsp;&nbsp;{getEnglishText('manual-schedule : new-schedule-item')}
        </div>
      </Segment>
    )
  }

  function TimeSpinner(props) {
    const { value, type, callback, step } = props

    const parseTime = (number) => {
      if (type === 'hour') {
        if (number > 23) return 0
        if (number < 0) return 24 - step
      } else if (type === 'minute') {
        if (number > 59) return 0
        if (number < 0) return 60 - step
      }
      return Math.round(number / step) * step
    }

    return (
      <div className="time-spinner">
        <Button size="big" icon onClick={(e) => callback(parseTime(parseInt(value, 10) + step))}>
          <Icon name="chevron up" />
        </Button>
        <Input value={value} />
        <Button size="big" icon onClick={(e) => callback(parseTime(parseInt(value, 10) - step))}>
          <Icon name="chevron down" />
        </Button>
      </div>
    )
  }

  function Slots() {
    // still loading
    if (!customerSchedule || typeof customerSchedule[selectedDay] !== 'object')
      return (
        <div style={{ marginTop: 100 }}>
          <Loader active inline="centered" className="workaround" />
        </div>
      )

    // list slots
    if (customerSchedule[selectedDay].length)
      return (
        <React.Fragment>
          <Segment.Group style={{ marginBottom: 0 }}>
            {saving && (
              <Dimmer active inverted>
                <Loader inverted />
              </Dimmer>
            )}
            {customerSchedule[selectedDay].map((slot, index) => {
              const { id, start, state } = slot
              const isSelected = selectedSlotId === id

              return (
                <Segment
                  key={index}
                  className={`schedule-slot color ${state} ${isSelected ? 'active' : 'inactive'} ${slotConflict == start ? 'conflict' : ''}`}
                  onClick={() => {
                    setSelectedSlotId(id)
                    setSaved(false)
                    setSlotRemoved(false)
                    setErrorMessage(null)
                    setSaveFailed(false)
                    setCopiedToDays(false)
                    setDayCleared(false)
                  }}
                >
                  <div className="schedule">
                    <Header as="h2" className="time">
                      {moment(start, 'HH:mm:ss').format('HH:mm')}
                    </Header>
                  </div>
                  <div className="state">
                    <span>{friendlyNameFromScheduleState(state)}</span>
                  </div>
                  {isSelected && (
                    // <Popup
                    //   inverted
                    //   trigger={
                    <Button
                      circular
                      icon
                      secondary
                      floated="right"
                      onClick={(e) => {
                        e.stopPropagation()
                        setSelectedSlotId(null)
                        updateScheduleObject({
                          daysChanged: [selectedDay],
                          ...customerSchedule,
                          [selectedDay]: customerSchedule[selectedDay].filter((slot) => slot.id !== id)
                        })
                        setSlotRemoved(true)
                      }}
                    >
                      <Icon name="close" />
                    </Button>
                    //   }
                    //   content="Delete this item"
                    // />
                  )}
                </Segment>
              )
            })}
          </Segment.Group>
          <NewItemButton />
        </React.Fragment>
      )

    // no slots
    if (!customerSchedule[selectedDay].length)
      return (
        <React.Fragment>
          <span>
            <em>{getEnglishText('manual-schedule : no-schedule-set-for-day')}</em>
          </span>
          <NewItemButton primary />
        </React.Fragment>
      )
  }

  function EditSlot() {
    if (saveFailed) {
      // save failed
      return (
        <Segment className="schedule-detail error" placeholder>
          <Header icon>
            <Icon name="exclamation" />
            <br />
            {getEnglishText('manual-schedule : save-failed-title')}
            <Header.Subheader>
              <>{getEnglishText('manual-schedule : save-failed-body')}</>
            </Header.Subheader>
          </Header>
        </Segment>
      )
    } else if (saving) {
      // saving
      return (
        <Segment className="schedule-detail saving" placeholder>
          <Dimmer active inverted>
            <Loader inverted size="big">
              {getEnglishText('common : saving')}
            </Loader>
          </Dimmer>
        </Segment>
      )
    } else if (copiedToDays) {
      // just been copied to all days
      return (
        <Segment className="schedule-detail copied-to-all-days" placeholder>
          <Header icon>
            <Icon name="check" />
            {getEnglishText('manual-schedule : schedule-copied-to')} {copiedToDays}.
          </Header>
        </Segment>
      )
    } else if (saved) {
      // just been saved
      return (
        <Segment className="schedule-detail saved" placeholder>
          <Header icon>
            <Icon name="check" />
            {getEnglishText('manual-schedule : schedule-saved')}
          </Header>
        </Segment>
      )
    } else if (slotRemoved) {
      // slot just been removed
      return (
        <Segment className="schedule-detail item-removed" placeholder>
          <Header icon>
            <Icon name="close" />
            {getEnglishText('manual-schedule : schedule-item-deleted')}
          </Header>
          {/* <Button onClick={createNewItem}>
            <Icon name="plus" /> {getEnglishText('manual-schedule : new-schedule-item')}
          </Button> */}
        </Segment>
      )
    } else if (dayCleared) {
      // day has just been cleared
      return (
        <Segment className="schedule-detail day-cleared" placeholder>
          <Header icon>
            <Icon name="close" />
            {`${selectedDay.substr(0, 1).toUpperCase()}${selectedDay.substr(1)}`}'s {getEnglishText('manual-schedule : schedule-cleared')}.
          </Header>
          {/* <Button onClick={createNewItem}>
            <Icon name="plus" /> {getEnglishText('manual-schedule : new-schedule-item')}
          </Button> */}
        </Segment>
      )
    } else if (selectedSlotId === null) {
      // no slot selected
      return (
        <Segment className="schedule-detail no-selection" placeholder>
          <Header icon>
            <Icon name="clock outline" />
            {getEnglishText('manual-schedule : select-a-schedule-item')},
            <span style={{ display: 'block', paddingTop: 8 }}>
              <em>{getEnglishText('common : or')}</em>
            </span>
          </Header>
          <Button onClick={createNewItem}>
            <Icon name="plus" /> {getEnglishText('manual-schedule : new-schedule-item')}
          </Button>
        </Segment>
      )
    }

    const { start, state } = customerSchedule[selectedDay].filter((slot) => slot.id === selectedSlotId)[0]

    function updateSelectedSlot({ newState, hour, minute }) {
      const [existingHour, existingMinute] = start.split(':')

      let [newHour, newMinute, newSecond] = [hour !== undefined ? hour : existingHour, minute !== undefined ? minute : existingMinute, '00']

      newHour = pad(parseInt(newHour, 10), 2)
      newMinute = pad(parseInt(newMinute, 10), 2)

      const newSlots = customerSchedule[selectedDay].map((slot) => {
        if (slot.id === selectedSlotId) {
          if (newState) return { ...slot, state: newState }
          else return { ...slot, start: [newHour, newMinute, newSecond].join(':') }
        } else {
          return slot
        }
      })

      updateScheduleObject({
        daysChanged: [selectedDay],
        ...customerSchedule,
        [selectedDay]: newSlots
      })
    }

    const availableStates = [
      { value: 'normal', text: friendlyNameFromScheduleState('normal'), className: 'color normal' },
      { value: 'only-charge', text: friendlyNameFromScheduleState('only-charge'), className: 'color only-charge' },
      {
        value: 'only-discharge',
        text: friendlyNameFromScheduleState('only-discharge'),
        className: 'color only-discharge'
      },
      {
        value: 'force-charge',
        text: friendlyNameFromScheduleState('force-charge'),
        className: 'color force-charge'
      },
      {
        value: 'force-discharge',
        text: friendlyNameFromScheduleState('force-discharge'),
        className: 'color force-discharge'
      },
      { value: 'disable', text: friendlyNameFromScheduleState('disable'), className: 'color disable' }
    ]
    if (dormantModeAvailable)
      availableStates.push({
        value: 'dormant',
        text: friendlyNameFromScheduleState('dormant'),
        className: 'color dormant',
        label: { color: 'red', content: isStaff ? 'staff' : 'beta', position: 'right', circular: true }
      })

    // edit slot
    return (
      <Segment className="schedule-detail" placeholder>
        <Grid columns={2} stackable textAlign="center">
          <Grid.Row verticalAlign="middle">
            <Grid.Column className="state-container">
              <Header as="h2">{getEnglishText('manual-schedule : set-state')}:</Header>
              <Dropdown
                className={`color ${state}`}
                placeholder={getEnglishText('manual-schedule : unit-state')}
                selection
                value={state}
                options={availableStates}
                onChange={(e, data) => updateSelectedSlot({ newState: data.value })}
              />
            </Grid.Column>
            <Grid.Column className="spinner-container">
              <Header as="h2">{getEnglishText('manual-schedule : at')}:</Header>
              <TimeSpinner value={moment(start, 'HH:mm:ss').format('HH')} step={1} type="hour" callback={(value) => updateSelectedSlot({ hour: value })} />
              <Header as="h2">:</Header>
              <TimeSpinner value={moment(start, 'HH:mm:ss').format('mm')} step={5} type="minute" callback={(value) => updateSelectedSlot({ minute: value })} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }

  function ButtonPanel() {
    return (
      <React.Fragment>
        <div className="left-buttons">
          <Button
            // icon
            // labelPosition="left"
            secondary
            basic
            disabled={saving || !customerSchedule}
            onClick={() => {
              setSelectedSlotId(null)
              setSlotRemoved(false)
              setErrorMessage(null)
              setSaveFailed(false)
              setCopiedToDays(false)
              setDayCleared(true)
              updateScheduleObject({
                daysChanged: [selectedDay],
                ...customerSchedule,
                [selectedDay]: []
              })
            }}
          >
            {/* <Icon name="remove" /> */}
            <span>{getEnglishText('manual-schedule : clear-day')}</span>
          </Button>
          <Dropdown text={getEnglishText('manual-schedule : copy-day-to')} button className="secondary basic">
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={saving || !customerSchedule || detectConflictsInCurrentDay()}
                onClick={() => {
                  if (!detectConflictsInCurrentDay()) {
                    updateScheduleObject({
                      daysChanged: 'all',
                      monday: [...customerSchedule[selectedDay]],
                      tuesday: [...customerSchedule[selectedDay]],
                      wednesday: [...customerSchedule[selectedDay]],
                      thursday: [...customerSchedule[selectedDay]],
                      friday: [...customerSchedule[selectedDay]],
                      saturday: [...customerSchedule[selectedDay]],
                      sunday: [...customerSchedule[selectedDay]]
                    })

                    setCopiedToDays('all days')
                    setErrorMessage(null)
                    setSaveFailed(false)
                  }
                }}
              >
                <span>{getEnglishText('manual-schedule : copy-to-all-days')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                disabled={saving || !customerSchedule || detectConflictsInCurrentDay()}
                onClick={() => {
                  if (!detectConflictsInCurrentDay()) {
                    updateScheduleObject({
                      daysChanged: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                      monday: [...customerSchedule[selectedDay]],
                      tuesday: [...customerSchedule[selectedDay]],
                      wednesday: [...customerSchedule[selectedDay]],
                      thursday: [...customerSchedule[selectedDay]],
                      friday: [...customerSchedule[selectedDay]],
                      saturday: [...customerSchedule['saturday']],
                      sunday: [...customerSchedule['sunday']]
                    })

                    setCopiedToDays('weekdays')
                    setErrorMessage(null)
                    setSaveFailed(false)
                  }
                }}
              >
                <span>{getEnglishText('manual-schedule : copy-to-weekdays')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                disabled={saving || !customerSchedule || detectConflictsInCurrentDay()}
                onClick={() => {
                  if (!detectConflictsInCurrentDay()) {
                    updateScheduleObject({
                      daysChanged: ['saturday', 'sunday'],
                      monday: [...customerSchedule['monday']],
                      tuesday: [...customerSchedule['tuesday']],
                      wednesday: [...customerSchedule['wednesday']],
                      thursday: [...customerSchedule['thursday']],
                      friday: [...customerSchedule['friday']],
                      saturday: [...customerSchedule[selectedDay]],
                      sunday: [...customerSchedule[selectedDay]]
                    })

                    setCopiedToDays('weekends')
                    setErrorMessage(null)
                    setSaveFailed(false)
                  }
                }}
              >
                <span>{getEnglishText('manual-schedule : copy-to-weekends')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {showPresets && (
            <Dropdown disabled={saving || !customerSchedule} text="Presets" style={{ padding: 10, marginLeft: 10 }}>
              <Dropdown.Menu>
                <Dropdown.Item text="Soak (Charge First)" onClick={() => loadPreset('soak-charge-first')} />
                <Dropdown.Item text="Soak (Discharge First)" onClick={() => loadPreset('soak-discharge-first')} />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        {unsavedChanges && (
          <Button primary disabled={!unsavedChanges || saving || detectConflictsInCurrentDay()} onClick={saveSchedule}>
            {getEnglishText('common : save-changes')}
          </Button>
        )}
        <Button
          secondary
          disabled={saving}
          onClick={() => {
            if (unsavedChanges) {
              setConfirmCloseModalOpen(true)
            } else {
              setMainModelOpen(false)
              if (onClose) onClose()
            }
          }}
        >
          {unsavedChanges ? getEnglishText('common : discard-changes') : getEnglishText('common : close')}
        </Button>
      </React.Fragment>
    )
  }

  function ModalContent() {
    return (
      <React.Fragment>
        <DayButtons />

        <div className="schedule-ribbon-container">
          <ScheduleRibbons
            unit={unit}
            showDays={showDaysInRibbon}
            scheduleOverride={{ customer: customerSchedule }}
            showScheduleTypes={['customer']}
            showScheduleNames={false}
            hideOverriddenScheduleTypes={false}
            showScale={true}
            showLegend={true}
            showDayNames={true}
            showEmptyDays={true}
            matchChartScale={false}
            highlightDay={[selectedDay]}
            dimNonHighlighted={true}
            dayClickHandler={(dayName) => {
              if (!detectConflictsInCurrentDay()) {
                setSelectedSlotId(null)
                setSlotRemoved(false)
                setErrorMessage(null)
                setSaveFailed(false)
                setCopiedToDays(false)
                setDayCleared(false)
                setSelectedDay(dayName)
                setShowDaysInRibbon([...daysOfWeek])
              }
            }}
          />
        </div>

        <Header>{`${selectedDay.substr(0, 1).toUpperCase()}${selectedDay.substr(1)}`}</Header>

        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={6}>
              <Slots />
            </Grid.Column>
            <Grid.Column width={10}>
              <EditSlot />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <div className="schedule">
      {/* main modal */}
      <Modal id="schedule-modal" open={mainModelOpen} closeOnEscape={false} closeOnDimmerClick={false} size="large" centered={false} dimmer="blurring">
        <Modal.Header>
          <Icon name="cogs" />
          &nbsp;&nbsp;&nbsp;
          {displayAsCluster ? <>{getEnglishText('manual-schedule : cluster-schedule')}</> : <>{getEnglishText('manual-schedule : manual-schedule')}</>}
        </Modal.Header>

        <Modal.Content>
          <Modal.Description>
            <ModalContent />
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          {errorMessage && (
            <Message error style={{ textAlign: 'left' }}>
              {errorMessage}
            </Message>
          )}
          <ButtonPanel />
        </Modal.Actions>
      </Modal>

      {/* confirm discard changes modal */}
      <Modal centered={false} open={confirmCloseModalOpen} size="tiny" dimmer="blurring">
        <Modal.Header>
          <Icon name="exclamation" />
          &nbsp;&nbsp;{getEnglishText('common : unsaved-changes')}
        </Modal.Header>
        <Modal.Content>{getEnglishText('manual-schedule : schedule-has-unsaved-changes')}</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content={getEnglishText('common : save-changes')}
            disabled={slotConflict}
            onClick={() => {
              saveSchedule()
              setConfirmCloseModalOpen(false)
            }}
          />
          <Button
            secondary
            content={getEnglishText('common : discard-changes')}
            onClick={() => {
              setConfirmCloseModalOpen(false)
              setMainModelOpen(false)
              if (onClose) onClose()
            }}
          />
          <Button secondary basic content={getEnglishText('common : cancel')} onClick={() => setConfirmCloseModalOpen(false)} />
        </Modal.Actions>
      </Modal>

      {/* copy to all days prompt */}
      <Modal centered={false} open={copyToAllDaysPromptOpen} size="tiny" dimmer="blurring">
        <Modal.Header>
          <Icon name="help" />
          &nbsp;&nbsp;{getEnglishText('manual-schedule : confirm-weekly-schedule')}
        </Modal.Header>
        <Modal.Content>
          {getEnglishText('manual-schedule : confirm-weekly-schedule-description')}
          <ul>
            {[...daysOfWeekTitleCase].map((dayName) => (
              <li key={dayName}>
                <div style={{ display: 'inline-block', width: '10em', fontWeight: 'bold' }}>{dayName}</div>
                <div style={{ display: 'inline-block' }}>
                  {changedDays.includes(dayName.toLowerCase()) ? (
                    <span style={{ color: '#21ba45', fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp; Changed</span>
                  ) : (
                    <span style={{ color: 'grey', opacity: 0.7 }}>&nbsp;&nbsp;&nbsp;&nbsp; Not changed</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <p></p>
        </Modal.Content>
        <Modal.Actions>
          {changedDays.length === 1 && (
            <Button
              secondary
              onClick={() => {
                setUndoBuffer({ changedDays: [...changedDays], customerSchedule: { ...customerSchedule } })

                updateScheduleObject({
                  daysChanged: 'all',
                  monday: [...customerSchedule[changedDays[0]]],
                  tuesday: [...customerSchedule[changedDays[0]]],
                  wednesday: [...customerSchedule[changedDays[0]]],
                  thursday: [...customerSchedule[changedDays[0]]],
                  friday: [...customerSchedule[changedDays[0]]],
                  saturday: [...customerSchedule[changedDays[0]]],
                  sunday: [...customerSchedule[changedDays[0]]]
                })

                setCopiedToDays('all days')
                setErrorMessage(null)
                setSaveFailed(false)
              }}
            >
              Copy {changedDays[0].substr(0, 1).toUpperCase() + changedDays[0].substr(1)} to all days
            </Button>
          )}
          <Button
            primary
            content={getEnglishText('common : save-changes')}
            disabled={slotConflict}
            onClick={() => {
              saveSchedule({ ignoreUnchangedDays: true })
              setCopyToAllDaysPromptOpen(false)
            }}
          />
          <Button
            secondary
            basic
            content={getEnglishText('common : cancel')}
            onClick={() => {
              if (Object.keys(undoBuffer).length) {
                updateScheduleObject({ ...undoBuffer.customerSchedule })
                setChangedDays([...undoBuffer.changedDays])
                setUndoBuffer({})
              }

              setCopiedToDays(false)
              setErrorMessage(null)
              setSaveFailed(false)

              setCopyToAllDaysPromptOpen(false)
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export { ScheduleEditor }
