import React, { useState, useEffect } from 'react'
import { Modal, Button, Menu, Segment, Image, Grid, Message, Loader, Header, Icon, Divider, Dimmer, Popup } from 'semantic-ui-react'

function ContactSupport() {
  return (
    <>
      <Header>Contact Us</Header>
      <Header>Email</Header>
      <div style={{ paddingLeft: '0.5em' }}>
        <a href="mailto:service@powervault.co.uk?subject=Customer Portal">service@powervault.co.uk</a>
      </div>
      <Header>Phone</Header>
      <div style={{ paddingLeft: '0.5em' }}>+44 (0)203 653 1111</div>
      <Header>Address</Header>
      <div style={{ paddingLeft: '0.5em' }}>
        Powervault, Unit 9,
        <br />
        Garrick Industrial Estate,
        <br />
        Irving Way, London NW9 6AQ
      </div>
      <Divider hidden />
      <Divider hidden />
    </>
  )
}

export { ContactSupport }
