import React, { useEffect, useState } from 'react'
import { Button, Icon, Segment, Dropdown, Message, Modal, Loader } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'
import { config } from '../../common'

import './SmartSchedule.css'

function SmartSchedule(props) {
  const idToken = authenticator.getToken()
  const { units, displayAsCluster, onClose, onSaveSchedule, tariffPickerOnlyMode } = props

  const [saving, setSaving] = useState(false)
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [saved, setSaved] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [showEditMessage, setShowEditMessage] = useState(false)

  const [settings, setSettings] = useState(null)
  const [tariffList, setTariffList] = useState(null)

  const [mainModelOpen, setMainModelOpen] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    ajax
      .fetchSettings(units[0].hardwareId, idToken)
      .then((settingsData) => {
        setSettings(settingsData)
      })
      .catch((error) => {
        setErrorMessage(error)
      })

    ajax
      .fetchTarifflist(idToken)
      .then((tariffsData) => {
        setTariffList({
          tou: tariffsData.tariffList.filter((tariff) => tariff.type === 'tou'),
          dynamic: tariffsData.tariffList.filter((tariff) => tariff.type === 'dynamic')
        })
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }, [units])

  async function putSmartSchedule(enabled) {
    setSaving(true)

    settings.contract.smartScheduleEnabled = enabled ? 1 : 0

    // add trigger for cloud to read
    settings.triggers = enabled ? ['enable-smartstor'] : ['disable-smartstor']

    function sendSmartSchedule(hardwareId) {
      return ajax.putSettings(hardwareId, idToken, settings)
    }

    if (units.length === 1) {
      // single unit

      sendSmartSchedule(units[0].hardwareId, idToken, settings)
        .then((response) => {
          const { result, errorMessage } = response
          if (result === 'success') {
            setShowSavedModal(true)
            setSaved(true)
            setSaving(false)
            setUnsavedChanges(false)
            setErrorMessage(null)
            if (onSaveSchedule) onSaveSchedule(Math.random())
          } else {
            setSaving(false)
            setErrorMessage(errorMessage)
          }
        })
        .catch((error) => {
          setErrorMessage(error)
        })
    } else {
      // mulitple units (cluster)

      let errors = []

      for (let i in units) {
        const response = await sendSmartSchedule(units[i].hardwareId)
        if (!response.result || response.result !== 'success') {
          errors.push(response)
        }
      }

      if (!errors.length) {
        setShowSavedModal(true)
        setSaved(true)
        setSaving(false)
        setUnsavedChanges(false)
        setErrorMessage(null)
        if (onSaveSchedule) onSaveSchedule(Math.random())
      } else {
        setSaving(false)
        setErrorMessage(errors[0])
      }
    }
  }

  function isValidForm() {
    if (!settings.customerAccount.tariffType) return false
    if (!settings.customerAccount.importTariffId) return false
    return true
  }

  return (
    <div className="smart-schedule">
      {/* main modal */}
      <Modal id="smart-schedule-modal" open={mainModelOpen} closeOnEscape={false} closeOnDimmerClick={false} size="small" centered={false} dimmer="blurring">
        <Modal.Header className="control-bar">
          <Icon name="cogs" />
          &nbsp;&nbsp;&nbsp;{displayAsCluster ? getEnglishText('smart-schedule : title-cluster') : getEnglishText('smart-schedule : title')}
        </Modal.Header>

        {settings && tariffList ? (
          <React.Fragment>
            <Modal.Content>
              <Modal.Description>
                {/* <Message negative>
                  SMARTSTOR&trade; is currently only available to <strong>Octopus Agile</strong> customers.
                </Message> */}

                {getEnglishText('smart-schedule : description')}

                <Segment basic>
                  <strong>Select Tariff Type:</strong>
                  <br />
                  <br />
                  <div
                    onClick={() => {
                      if (settings.contract.smartScheduleEnabled === 1) setShowEditMessage(true)
                    }}
                  >
                    <Dropdown
                      placeholder="Select Tariff Type"
                      disabled={saving || settings.contract.smartScheduleEnabled === 1}
                      fluid
                      selection
                      value={settings.customerAccount.tariffType}
                      options={[
                        {
                          key: 'none',
                          value: null,
                          text: getEnglishText('smart-schedule : no-tariff')
                        },
                        {
                          key: 'tou',
                          value: 'tou',
                          text: getEnglishText('smart-schedule : time-of-used-tariff')
                        },
                        {
                          key: 'dynamic',
                          value: 'dynamic',
                          text: getEnglishText('smart-schedule : dynamic-tariff')
                        }
                      ]}
                      onChange={(e, { value }) => {
                        setSettings({
                          ...settings,
                          customerAccount: {
                            ...settings.customerAccount,
                            tariffType: value,
                            importTariffId: null,
                            exportTariffId: null
                          }
                        })
                        setUnsavedChanges(true)
                      }}
                    />
                  </div>
                  <br />
                  <strong>{getEnglishText('smart-schedule : select-import-tariff')}</strong>
                  <br />
                  <br />
                  <div
                    onClick={() => {
                      if (settings.contract.smartScheduleEnabled === 1) setShowEditMessage(true)
                    }}
                  >
                    <Dropdown
                      placeholder={getEnglishText('smart-schedule : no-import-tariff-selected')}
                      disabled={saving || settings.contract.smartScheduleEnabled === 1}
                      fluid
                      selection
                      value={settings.customerAccount.importTariffId}
                      options={tariffList[settings.customerAccount.tariffType || 'tou']
                        .filter((tariff) => tariff.mode === 'import')
                        .map((tariff) => {
                          return {
                            key: tariff.tariffId,
                            value: tariff.tariffId,
                            text: `${tariff.supplier} - ${tariff.name}`,
                            image: { src: `static/images/suppliers/${tariff.supplier.replace(/ /g, '')}_menu.png` }
                          }
                        })}
                      onChange={(e, { value }) => {
                        setSettings({
                          ...settings,
                          customerAccount: { ...settings.customerAccount, importTariffId: value }
                        })
                        setUnsavedChanges(true)
                      }}
                    />
                  </div>
                  <br />

                  {settings.customerAccount.tariffType !== 'tou' && (
                    <>
                      <strong>{getEnglishText('smart-schedule : select-export-tariff')}</strong>
                      <br />
                      <br />
                      <div
                        onClick={() => {
                          if (settings.contract.smartScheduleEnabled === 1) setShowEditMessage(true)
                        }}
                      >
                        <Dropdown
                          placeholder={getEnglishText('smart-schedule : no-export-tariff-selected')}
                          disabled={saving || settings.contract.smartScheduleEnabled === 1}
                          fluid
                          selection
                          value={settings.customerAccount.exportTariffId}
                          options={tariffList[settings.customerAccount.tariffType || 'tou']
                            .filter((tariff) => tariff.mode === 'export')
                            .map((tariff) => {
                              return {
                                key: tariff.tariffId,
                                value: tariff.tariffId,
                                text: `${tariff.supplier} - ${tariff.name}`,
                                image: { src: `static/images/suppliers/${tariff.supplier.replace(/ /g, '')}_menu.png` }
                              }
                            })
                            .concat({
                              key: null,
                              value: null,
                              text: getEnglishText('smart-schedule : no-export-tariff')
                            })}
                          onChange={(e, { value }) => {
                            setSettings({
                              ...settings,
                              customerAccount: { ...settings.customerAccount, exportTariffId: value }
                            })
                            setUnsavedChanges(true)
                          }}
                        />
                      </div>
                      <br />
                    </>
                  )}

                  {/* <div
                    style={{ textAlign: 'right', marginTop: 10 }}
                    onClick={() => {
                      if (settings.contract.smartScheduleEnabled === 1) setShowEditMessage(true)
                    }}
                  >
                    <Checkbox
                      toggle
                      disabled={saving || settings.contract.smartScheduleEnabled === 1 || settings.contract.dfsOptIn === 1}
                      label="Opt in to SMARTSTOR™ 2.0"
                      checked={settings.contract.betaOptIn === 1}
                      onChange={(e, { checked }) => {
                        setSettings({
                          ...settings,
                          contract: {
                            ...settings.contract,
                            betaOptIn: checked ? 1 : 0
                          }
                        })
                        setUnsavedChanges(true)
                      }}
                    />
                    &nbsp;&nbsp;
                    <Label style={{ display: 'inline-block', verticalAlign: 'top' }} color="red" content="beta" size="mini" />
                  </div> */}
                </Segment>

                {showEditMessage && <Message error>{getEnglishText('smart-schedule : please-first-disable')}</Message>}

                {errorMessage && <Message error>{errorMessage}</Message>}

                {config.dfs.showDFS && settings.contract.dfsOptIn === 1 && <Message warning>{getEnglishText('smart-schedule : overridden-by-dfs')}</Message>}
              </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
              <div
                className={`status ${
                  settings.contract.smartScheduleEnabled ? (settings.contract.dfsOptIn === 1 ? 'overriden-by-dfs' : 'enabled') : 'disabled'
                }`}
              >
                {getEnglishText('smart-schedule : status')}:{' '}
                <span>
                  {settings.contract.smartScheduleEnabled
                    ? config.dfs.showDFS && settings.contract.dfsOptIn === 1
                      ? getEnglishText('smart-schedule : enabled-can-be-overridden')
                      : getEnglishText('smart-schedule : enabled')
                    : getEnglishText('smart-schedule : disabled')}
                </span>
              </div>

              {unsavedChanges ? (
                <div>
                  <Button
                    secondary
                    basic={unsavedChanges}
                    disabled={saving}
                    onClick={() => {
                      setMainModelOpen(false)
                      if (onClose) onClose()
                    }}
                  >
                    {getEnglishText('common : discard-changes')}
                  </Button>
                  <Button
                    secondary
                    basic={unsavedChanges}
                    disabled={saving}
                    onClick={() => {
                      putSmartSchedule(settings.contract.smartScheduleEnabled)
                      setMainModelOpen(false)
                      if (onClose) onClose()
                    }}
                  >
                    {getEnglishText('common : save-changes')}
                  </Button>
                </div>
              ) : (
                <Button
                  secondary
                  basic={unsavedChanges}
                  disabled={saving}
                  onClick={() => {
                    setMainModelOpen(false)
                    if (onClose) onClose()
                  }}
                >
                  {getEnglishText('common : close')}
                </Button>
              )}

              {settings.contract.smartScheduleEnabled ? (
                <Button
                  basic
                  secondary
                  disabled={saving}
                  loading={saving}
                  onClick={() => {
                    setShowEditMessage(false)
                    putSmartSchedule(false)
                  }}
                >
                  {getEnglishText('common : disable')}
                </Button>
              ) : (
                <Button
                  primary
                  disabled={!isValidForm() || saving}
                  loading={saving}
                  onClick={() => {
                    setShowEditMessage(false)
                    putSmartSchedule(true)
                  }}
                >
                  {getEnglishText('common : enable')}
                </Button>
              )}
            </Modal.Actions>
          </React.Fragment>
        ) : (
          <>
            <Modal.Content>
              <Modal.Description>
                <br />
                <Loader active inline="centered" className="workaround">
                  {getEnglishText('common : loading')}
                </Loader>
                <br />
                {/* <Segment loading>Loading...</Segment> */}
              </Modal.Description>
            </Modal.Content>
          </>
        )}
      </Modal>

      {/* saved modal */}
      <Modal centered={false} open={showSavedModal} size="tiny" dimmer="blurring">
        <Modal.Header>
          <Icon name="check" />
          &nbsp;&nbsp;{getEnglishText('smart-schedule : changes-saved-title')}
        </Modal.Header>
        <Modal.Content>{getEnglishText('smart-schedule : changes-saved-description')}</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content={getEnglishText('smart-schedule : understood')}
            onClick={() => {
              if (settings.contract.smartScheduleEnabled === 1 && settings.contract.dfsOptIn === 0) {
                setMainModelOpen(false)
                if (onClose) onClose()
              }
              setShowSavedModal(false)
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export { SmartSchedule }
