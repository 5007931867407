import React, { useState, useEffect } from 'react'
import { ajax } from '../../ajax'
import { moment, parseModelCode } from '../../common'
import { Label, Icon, Popup } from 'semantic-ui-react'

import './VOCSensor.css'

// const ONLY_SHOW_FOR_UNITS = [
//   'P3-0032',
//   'P3-0053',
//   'P3-0062',
//   'P3-0064',
//   'P3-0079',
//   'P3-0085',
//   'P3-0092',
//   'P3-0104',
//   'P3-0114',
//   'P3-0115',
//   'P3-0118',
//   'P3-0119',
//   'P3-0120',
//   'P3-0125',
//   'P3-0127',
//   'P3-0135',
//   'P3-0138',
//   'P3-0141',
//   'P3-0142',
//   'P3-0148',
//   'P3-0152',
//   'P3-0155',
//   'P3-0156',
//   'P3-0159',
//   'P3-0166',
//   'P3-0173',
//   'P3-0175',
//   'P3-0187',
//   'P3-0188',
//   'P3-0197',
//   'P3-0203',
//   'P3-0205',
//   'P3-0209',
//   'P3-0210',
//   'P3-0215',
//   'P3-0223',
//   'P3-0224',
//   'P3-0230',
//   'P3-0231',
//   'P3-0233',
//   'P3-0235',
//   'P3-0236',
//   'P3-0241',
//   'P3-0246',
//   'P3-0248',
//   'P3-0249',
//   'P3-0253',
//   'P3-0254',
//   'P3-0259',
//   'P3-0263',
//   'P3-0265',
//   'P3-0267',
//   'P3-0269',
//   'P3-0270',
//   'P3-0271',
//   'P3-0274',
//   'P3-0276',
//   'P3-0281',
//   'P3-0282',
//   'P3-0283',
//   'P3-0285',
//   'P3-0297',
//   'P3-0300',
//   'P3-0304',
//   'P3-0307',
//   'P3-0309',
//   'P3-0312',
//   'P3-0313',
//   'P3-0316',
//   'P3-0317',
//   'P3-0318',
//   'P3-0320',
//   'P3-0322',
//   'P3-0327',
//   'P3-0329',
//   'P3-0330',
//   'P3-0331',
//   'P3-0333',
//   'P3-0336',
//   'P3-0337',
//   'P3-0338',
//   'P3-0341',
//   'P3-0345',
//   'P3-0346',
//   'P3-0350',
//   'P3-0351',
//   'P3-0352',
//   'P3-0355',
//   'P3-0357',
//   'P3-0358',
//   'P3-0361',
//   'P3-0363',
//   'P3-0366',
//   'P3-0368',
//   'P3-0369',
//   'P3-0370',
//   'P3-0371',
//   'P3-0381',
//   'P3-0386',
//   'P3-0389',
//   'P3-0392',
//   'P3-0393',
//   'P3-0394',
//   'P3-0398',
//   'P3-0400',
//   'P3-0403',
//   'P3-0406',
//   'P3-0408',
//   'P3-0412',
//   'P3-0414',
//   'P3-0415',
//   'P3-0417',
//   'P3-0430',
//   'P3-0431',
//   'P3-0432',
//   'P3-0434',
//   'P3-0436',
//   'P3-0439',
//   'P3-0441',
//   'P3-0443',
//   'P3-0445',
//   'P3-0446',
//   'P3-0448',
//   'P3-0450',
//   'P3-0451',
//   'P3-0454',
//   'P3-0457',
//   'P3-0461',
//   'P3-0465',
//   'P3-0467',
//   'P3-0468',
//   'P3-0469',
//   'P3-0472',
//   'P3-0475',
//   'P3-0477',
//   'P3-0480',
//   'P3-0481',
//   'P3-0482',
//   'P3-0485',
//   'P3-0486',
//   'P3-0487',
//   'P3-0488',
//   'P3-0493',
//   'P3-0495',
//   'P3-0496',
//   'P3-0503',
//   'P3-0506',
//   'P3-0507',
//   'P3-0508',
//   'P3-0511',
//   'P3-0513',
//   'P3-0514',
//   'P3-0516',
//   'P3-0517',
//   'P3-0524',
//   'P3-0525',
//   'P3-0526',
//   'P3-0527',
//   'P3-0538',
//   'P3-0541',
//   'P3-0542',
//   'P3-0543',
//   'P3-0544',
//   'P3-0548',
//   'P3-0554',
//   'P3-0568',
//   'P3-0570',
//   'P3-0574',
//   'P3-0583',
//   'P3-0628',
//   'P3-0631',
//   'P3-0632',
//   'P3-0639',
//   'P3-0641',
//   'P3-0643',
//   'P3-0644',
//   'P3-0646',
//   'P3-0647',
//   'P3-0648',
//   'P3-0650',
//   'P3-0651',
//   'P3-0653',
//   'P3-0507',
//   'P3-0436',
//   'P3-0064',
//   'P3-0241',
//   'P3-0236',
//   'P3-0309',
//   'P3-0389',
//   'P3-0249',
//   'P3-0445',
//   'P3-0412',
//   'P3-0270',
//   'P3-0538',
//   'P3-0276',
//   'P3-0370',
//   'P3-0304',
//   'P3-0650',
//   'P3-0327',
//   'P3-0250',
//   'P3-0303',
//   'P3-0437',
//   'P3-0549',
//   'P3-0270',
//   'P3-0272',
//   'P3-0384',
//   'P3-0420',
//   'P3-0545',
//   'P3-0326',
//   'P3-0229',
//   'P3-0275',
//   'P3-0452',
//   'P3-0093',
//   'P3-0349',
//   'P3-0018',
//   'P3-0194',
//   'P3-0547',
//   'P3-0449',
//   'P3-0642',
//   'P3-0157',
//   'P3-0098',
//   'P3-0328',
//   'P3-0473',
//   'P3-0562',
//   'P3-0268',
//   'P3-0456',
//   'P3-0323',
//   'P3-0153',
//   'P3-0576',
//   'P3-0388',
//   'P3-0262',
//   'P3-0301',
//   'P3-0500',
//   'P3-0429',
//   'P3-0083',
//   'P3-0090',
//   'P3-0501',
//   'P3-0161',
//   'P3-0557',
//   'P3-0186',
//   'P3-0208',
//   'P3-0216',
//   'P3-0515',
//   'P3-0572',
//   'P3-0362',
//   'P3-0356',
//   'P3-0099',
//   'P3-0416',
//   'P3-0504',
//   'P3-0418',
//   'P3-0405',
//   'P3-0151',
//   'P3-0497',
//   'P3-0411',
//   'P3-0179',
//   'P3-0185',
//   'P3-0635',
//   'P3-0580',
//   'P3-0479',
//   'P3-0021',
//   'P3-0102',
//   'P3-0140',
//   'P3-0158',
//   'P3-0204',
//   'P3-0238',
//   'P3-0261',
//   'P3-0373',
//   'P3-0379',
//   'P3-0395',
//   'P3-0397',
//   'P3-0453',
//   'P3-0466',
//   'P3-0490',
//   'P3-0566'
// ]

const UPDATE_INTERVAL_MS = 5000
const FRESH_DATA_THRESHOLD_SECONDS = 60 * 5 // 5 minutes
let timeoutHandle = null

function VOCSensor({ units }) {
  const [sensorStatuses, setSensorStatuses] = useState([])

  useEffect(() => {
    return () => clearTimeout(timeoutHandle)
  }, [])

  useEffect(() => {
    // if (units.some(({ configId }) => ONLY_SHOW_FOR_UNITS.includes(configId))) {
    const unitsWithSensors = units.filter(({ model }) => parseModelCode(model).vocSensorFitted)
    if (unitsWithSensors.length > 0) {
      fetchData(unitsWithSensors)
    } else {
      setSensorStatuses([])
      clearTimeout(timeoutHandle)
    }
    // }
  }, [units])

  async function fetchData(unitsWithSensors) {
    const statuses = await Promise.all(
      unitsWithSensors.map(async (unit) => {
        const response = await ajax.fetchLastMeasurement({
          hardwareId: unit.hardwareId,
          measurementName: 'vocSensorConnected'
        })
        const lastDataTime = response?.lastData?.vocSensorConnected[0]
        const dataIsFresh = moment.utc(lastDataTime).isAfter(moment.utc().subtract(FRESH_DATA_THRESHOLD_SECONDS, 'seconds'))

        if (!dataIsFresh) return { hardwareId: unit.hardwareId, status: 'disconnected' }

        const vocValue = response?.lastData?.vocSensorConnected[1]
        return {
          hardwareId: unit.hardwareId,
          status: vocValue ? 'online' : 'disconnected'
        }
      })
    )

    setSensorStatuses(statuses)

    clearTimeout(timeoutHandle)
    timeoutHandle = setTimeout(() => fetchData(unitsWithSensors), UPDATE_INTERVAL_MS)
  }

  const onlineCount = sensorStatuses.filter((sensor) => sensor.status === 'online').length
  const offlineCount = sensorStatuses.filter((sensor) => sensor.status === 'disconnected').length

  const isSingleUnit = units.length === 1

  // if (!units.some(({ configId }) => ONLY_SHOW_FOR_UNITS.includes(configId))) return null

  return (
    <div className="VOCSensor">
      {onlineCount > 0 && (
        <Label color="green" basic size="small">
          {isSingleUnit ? 'VOC sensor online' : `${onlineCount} VOC sensor${onlineCount > 1 ? 's' : ''} online`}
          &nbsp;&nbsp;&nbsp;
          <Icon name="check" fitted />
        </Label>
      )}
      {offlineCount > 0 && (
        <Popup
          content={`Please check the ${offlineCount} disconnected VOC sensor${offlineCount > 1 ? 's' : ''} or contact us for help.`}
          trigger={
            <Label basic size="small">
              <Icon name="info" fitted />
              &nbsp;&nbsp;&nbsp;
              {isSingleUnit ? 'VOC sensor disconnected' : `${offlineCount} VOC sensor${offlineCount > 1 ? 's' : ''} disconnected`}
            </Label>
          }
        />
      )}
    </div>
  )
}

export { VOCSensor }
