import React, { useEffect, useState } from 'react'
import { Icon, Button, Checkbox, Header, Label, Segment, Dimmer, Dropdown, Input, Popup, Message, Divider, Modal } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import './DFS.css'

function DFS(props) {
  const idToken = authenticator.getToken()
  const { hardwareId, onClose } = props

  const [saving, setSaving] = useState(false)
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [saved, setSaved] = useState(false)

  const [energySuppliers, setEnergySuppliers] = useState(null)
  const [dfsSettings, setDfsSettings] = useState(null)

  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const [mainModelOpen, setMainModelOpen] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    fetchData()
  }, [hardwareId])

  function fetchData() {
    // energy suppliers
    ajax
      .fetchRaw(idToken, 'energySuppliers')
      .then((data) => {
        if (data.result === 'success') setEnergySuppliers(data.energySuppliers)
      })
      .catch((error) => {
        setErrorMessage(error)
      })

    // dfs settings
    ajax
      .fetchSettings(hardwareId, idToken)
      .then((settingsData) => {
        setDfsSettings(settingsData)
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  function putDfsSettings(dfsSettings) {
    setSaving(true)

    // add trigger for cloud to read
    dfsSettings.triggers = dfsSettings.contract.dfsOptIn === 1 ? ['enable-dfs'] : ['disable-dfs']

    ajax
      .putSettings(hardwareId, idToken, dfsSettings)
      .then((response) => {
        const { result, errorMessage } = response
        if (result === 'success') {
          setShowSavedModal(true)
          setSaved(true)
          setSaving(false)
          setErrorMessage(null)
        } else {
          setSaving(false)
          setErrorMessage(errorMessage)
        }
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  return (
    <div className="dfs">
      {/* main modal */}
      <Modal id="dfs-modal" open={mainModelOpen} closeOnEscape={false} closeOnDimmerClick={false} size="small" centered={false} dimmer="blurring">
        <Modal.Header className="control-bar">
          <Icon name="cogs" />
          &nbsp;&nbsp;&nbsp;{getEnglishText('dfs : modal-title')}
        </Modal.Header>

        {dfsSettings && (
          <React.Fragment>
            <Modal.Content>
              <Modal.Description>
                {getEnglishText('dfs : modal-content')}

                <Header as="h3">{getEnglishText('dfs : select-your-dfs-supplier')}</Header>

                {energySuppliers && (
                  <>
                    <Dropdown
                      placeholder="No DFS Supplier selected"
                      disabled={saving || dfsSettings.contract.dfsOptIn === 1}
                      fluid
                      selection
                      value={dfsSettings.contract.dfsSupplierId}
                      options={energySuppliers
                        .filter((energySupplier) => energySupplier.dfsEnabled === 1)
                        .map((energySupplier) => {
                          return {
                            key: energySupplier.id,
                            value: energySupplier.id,
                            text: `${energySupplier.name}`,
                            image: { src: `static/images/dfs-suppliers/${energySupplier.uid.replace(/[\W_]+/g, '-')}.png` }
                          }
                        })}
                      onChange={(e, { value }) => {
                        setDfsSettings({
                          ...dfsSettings,
                          contract: { ...dfsSettings.contract, dfsSupplierId: value }
                        })
                        setUnsavedChanges(true)
                      }}
                    />
                  </>
                )}

                {errorMessage && <Message error>{errorMessage}</Message>}
              </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
              <div className={`status ${dfsSettings.contract.dfsOptIn === 1 ? 'enabled' : 'disabled'}`}>
                {getEnglishText('common : status')}:{' '}
                <span>{dfsSettings.contract.dfsOptIn === 1 ? getEnglishText('common : enabled') : getEnglishText('common : disabled')}</span>
              </div>

              <Button
                secondary
                basic={unsavedChanges}
                disabled={saving}
                onClick={() => {
                  setMainModelOpen(false)
                  if (onClose) onClose()
                }}
              >
                {unsavedChanges ? getEnglishText('common : discard-changes') : getEnglishText('common : close')}
              </Button>

              {dfsSettings.contract.dfsOptIn === 1 ? (
                <Button
                  secondary
                  disabled={saving}
                  loading={saving}
                  onClick={() => {
                    putDfsSettings({
                      ...dfsSettings,
                      contract: { ...dfsSettings.contract, dfsOptIn: 0 }
                    })
                  }}
                >
                  {getEnglishText('common : disable')}
                </Button>
              ) : (
                <Button
                  primary
                  disabled={saving || !dfsSettings.contract.dfsSupplierId}
                  loading={saving}
                  onClick={() => {
                    putDfsSettings({
                      ...dfsSettings,
                      contract: { ...dfsSettings.contract, dfsOptIn: 1 }
                    })
                  }}
                >
                  {getEnglishText('common : enable')}
                </Button>
              )}
            </Modal.Actions>
          </React.Fragment>
        )}
      </Modal>

      {/* saved modal */}
      <Modal centered={false} open={showSavedModal} size="tiny" dimmer="blurring">
        <Modal.Header>
          <Icon name="check" />
          &nbsp;&nbsp;{getEnglishText('dfs : changes-saved-title')}}
        </Modal.Header>
        <Modal.Content>{getEnglishText('dfs : changes-saved-content')}</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Understood"
            onClick={() => {
              setMainModelOpen(false)
              setShowSavedModal(false)
              setSaving(false)
              setSaved(false)
              setDfsSettings(null)
              setUnsavedChanges(false)
              setErrorMessage(null)
              if (onClose) onClose()
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export { DFS }
