import React, { useEffect, useState } from 'react'
import { Grid, Button, Loader, Header, Icon, Segment, Dimmer, Dropdown, Input, Message, Divider, Modal } from 'semantic-ui-react'
import moment from 'moment'
import { authenticator } from '../../authenticator'
import { friendlyNameFromScheduleState, parseFirmwareString, parseModelCode } from '../../common'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import EPSScheduleRibbonDay from './EPSScheduleRibbonDay'
import epsSchedulePresets from '../EPSSchedule/epsSchedulePresets'

import './EPSScheduleEditor.css'

function EPSScheduleEditor(props) {
  const idToken = authenticator.getToken()
  const { hardwareId, clusters, unitDetails, onSaveSchedule, onClose } = props
  const { model } = unitDetails
  const { onePercentEpsReservedSoc } = parseModelCode(model)

  console.log('onePercentEpsReservedSoc', onePercentEpsReservedSoc)

  const minReservedSoc = onePercentEpsReservedSoc ? 1 : 20

  const [selectedDay, setSelectedDay] = useState(moment().format('dddd').toLowerCase())
  const [selectedSlotId, setSelectedSlotId] = useState(null)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [slotRemoved, setSlotRemoved] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [copiedToAllDays, setCopiedToAllDays] = useState(false)
  const [dayCleared, setDayCleared] = useState(false)

  const [mainModelOpen, setMainModelOpen] = useState(true)
  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [slotConflict, setSlotConflict] = useState(false)
  const [saveFailed, setSaveFailed] = useState(false)

  const [epsSchedule, setEpsSchedule] = useState(null)

  const [displayAsCluster, setDisplayAsCluster] = useState(false)

  useEffect(() => {
    fetchSchedule()
  }, [hardwareId])

  useEffect(() => {
    setDisplayAsCluster(clusters[0] && clusters[0][0] && clusters[0][0].clusterHash)
  }, [clusters])

  function fetchSchedule() {
    ajax
      .fetchUnitSchedules(hardwareId, idToken)
      .then((scheduleData) => {
        setEpsSchedule(addRandomIds(scheduleData.epsSchedule))
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  async function saveSchedule() {
    setSaving(true)

    function sendSchedule(hardwareId) {
      return ajax.putUnitSchedule(hardwareId, idToken, 'eps', stripRandomIds(epsSchedule))
    }

    const hardwareIds = clusters[0].map((u) => u.hardwareId)

    if (hardwareIds.length === 1) {
      // single unit
      sendSchedule(hardwareIds[0])
        .then((response) => {
          const { result, errorMessage } = response
          if (result === 'success') {
            setSaved(true)
            setSaving(false)
            setUnsavedChanges(false)
            setErrorMessage(null)
            setSaveFailed(false)
            setCopiedToAllDays(false)
            setDayCleared(false)
            setSelectedSlotId(null)
            setSlotRemoved(false)

            if (onSaveSchedule) onSaveSchedule(epsSchedule)
          } else if (errorMessage === 'no-schedule-response-from-unit') {
            setSaving(false)
            setSaveFailed(true)
          } else {
            setSaving(false)
            setErrorMessage(errorMessage)
          }
        })
        .catch((error) => {
          setErrorMessage(error)
        })
    } else {
      // mulitple units
      let errors = []

      for (let hardwareId of hardwareIds) {
        const response = await sendSchedule(hardwareId)
        if (!response.result || response.result !== 'success') {
          errors.push(response)
        }
      }

      if (!errors.length) {
        setSaved(true)
        setSaving(false)
        setUnsavedChanges(false)
        setErrorMessage(null)
        setSaveFailed(false)
        setCopiedToAllDays(false)
        setDayCleared(false)
        setSelectedSlotId(null)
        setSlotRemoved(false)

        if (onSaveSchedule) onSaveSchedule(epsSchedule)
      } else {
        setErrorMessage(errors[0])
      }
    }
  }

  function pad(num, size) {
    var s = num + ''
    while (s.length < size) s = '0' + s
    return s
  }

  function getRandomId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  function stripRandomIds(schedule) {
    function stripIds(slots) {
      return slots.map((slot) => ({
        start: slot.start,
        end: slot.end,
        state: slot.state,
        reservedSoc: slot.reservedSoc
      }))
    }
    return {
      monday: stripIds([...schedule.monday]),
      tuesday: stripIds([...schedule.tuesday]),
      wednesday: stripIds([...schedule.wednesday]),
      thursday: stripIds([...schedule.thursday]),
      friday: stripIds([...schedule.friday]),
      saturday: stripIds([...schedule.saturday]),
      sunday: stripIds([...schedule.sunday])
    }
  }

  function addRandomIds(schedule) {
    function addIds(slots) {
      return slots.map((slot) => ({ ...slot, id: getRandomId() }))
    }
    return {
      monday: addIds([...schedule.monday]),
      tuesday: addIds([...schedule.tuesday]),
      wednesday: addIds([...schedule.wednesday]),
      thursday: addIds([...schedule.thursday]),
      friday: addIds([...schedule.friday]),
      saturday: addIds([...schedule.saturday]),
      sunday: addIds([...schedule.sunday])
    }
  }

  function detectConflictsInCurrentDay() {
    let conflict = false

    epsSchedule[selectedDay].forEach((slotA) => {
      const { start: startA, id: idA } = slotA

      epsSchedule[selectedDay].forEach((slotB) => {
        const { start: startB, id: idB } = slotB

        if (idA === idB) return

        if (startA === startB) conflict = startA
      })
    })

    if (conflict) {
      setErrorMessage(getEnglishText('eps-schedule : conflicting-schedule'))
      setSlotConflict(conflict)
    } else {
      setErrorMessage(null)
      setSlotConflict(false)
    }

    return conflict ? true : false
  }

  function updateScheduleObject(newSchedule) {
    function orderSlots(slots) {
      return slots.sort((a, b) => {
        const aSeconds = moment(a.start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
        const bSeconds = moment(b.start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
        return aSeconds - bSeconds
      })
    }

    function stretchEndTimes(slots) {
      if (slots.length) {
        for (let i = 0; i < slots.length - 1; i++) {
          slots[i].end = moment(slots[i + 1].start, 'HH:mm:ss')
            .subtract(1, 'second')
            .format('HH:mm:ss')
        }
        slots[slots.length - 1].end = '23:59:59'
      }
    }

    orderSlots(newSchedule.monday)
    orderSlots(newSchedule.tuesday)
    orderSlots(newSchedule.wednesday)
    orderSlots(newSchedule.thursday)
    orderSlots(newSchedule.friday)
    orderSlots(newSchedule.saturday)
    orderSlots(newSchedule.sunday)

    stretchEndTimes(newSchedule.monday)
    stretchEndTimes(newSchedule.tuesday)
    stretchEndTimes(newSchedule.wednesday)
    stretchEndTimes(newSchedule.thursday)
    stretchEndTimes(newSchedule.friday)
    stretchEndTimes(newSchedule.saturday)
    stretchEndTimes(newSchedule.sunday)

    // force correct day order and set
    setEpsSchedule({
      monday: [...newSchedule.monday],
      tuesday: [...newSchedule.tuesday],
      wednesday: [...newSchedule.wednesday],
      thursday: [...newSchedule.thursday],
      friday: [...newSchedule.friday],
      saturday: [...newSchedule.saturday],
      sunday: [...newSchedule.sunday]
    })

    setSaved(false)
    setSaving(false)
    setErrorMessage(null)
    setSaveFailed(false)
    setCopiedToAllDays(false)
    setDayCleared(false)
    setSlotRemoved(false)

    setUnsavedChanges(true)
  }

  function loadPreset(preset) {
    updateScheduleObject(epsSchedulePresets[preset])
  }

  function createNewItem() {
    function add5MinutesToTime(timeString) {
      let [hours, minutes, seconds] = timeString.split(':')

      hours = parseInt(hours, 10)
      minutes = parseInt(minutes, 10) + 5
      seconds = parseInt(seconds, 10)

      if (minutes > 59) {
        minutes = 0
        hours++
        if (hours > 23) hours = 0
      }

      return [pad(hours, 2), pad(minutes, 2), pad(seconds, 2)].join(':')
    }
    const randomId = getRandomId()

    let slotStart = '00:00:00' // default new slot to midnight

    if (selectedSlotId) {
      // insert after currently selected slot
      slotStart = add5MinutesToTime(epsSchedule[selectedDay].filter((slot) => slot.id === selectedSlotId)[0].start)
    } else if (epsSchedule[selectedDay].length) {
      // insert at end of slots
      slotStart = add5MinutesToTime(epsSchedule[selectedDay][epsSchedule[selectedDay].length - 1].start)
    }

    updateScheduleObject({
      ...epsSchedule,
      [selectedDay]: [
        ...epsSchedule[selectedDay],
        {
          start: slotStart,
          end: slotStart,
          state: 'eps-enabled',
          reservedSoc: minReservedSoc,
          id: randomId
        }
      ]
    })

    setSelectedSlotId(randomId)
    setSaved(false)
    setSlotRemoved(false)
    setErrorMessage(null)
    setSaveFailed(false)
    setCopiedToAllDays(false)
    setDayCleared(false)
  }

  function DayButtons() {
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

    // Mon | Tue | Wed | Thu | Fri | Sat | Sun

    return (
      <Segment className="day-buttons" textAlign="center" basic style={{ paddingTop: 0, marginBottom: 0 }}>
        <Button.Group>
          {daysOfWeek.map((dayName) => {
            const isActive = selectedDay === dayName
            return (
              <Button
                disabled={saving}
                key={dayName}
                active={isActive}
                negative={isActive}
                // color={isActive ? "orange" : null}
                onClick={() => {
                  if (!isActive) {
                    if (!detectConflictsInCurrentDay()) {
                      setSelectedSlotId(null)
                      setSlotRemoved(false)
                      setErrorMessage(null)
                      setSaveFailed(false)
                      setCopiedToAllDays(false)
                      setDayCleared(false)
                      setSelectedDay(dayName)
                    }
                  }
                }}
              >
                {`${dayName.substr(0, 1).toUpperCase()}${dayName.substr(1, 2)}`}
              </Button>
            )
          })}
        </Button.Group>
      </Segment>
    )
  }

  function NewItemButton({ primary }) {
    return (
      <Segment
        className={`schedule-slot new-button ${primary && 'primary pv-primary-background-color'}`}
        onClick={saving || !epsSchedule ? null : createNewItem}
        disabled={saving || !epsSchedule}
      >
        <div>
          <Icon name="plus" size="large" /> {getEnglishText('eps-schedule : new-schedule-item')}
        </div>
      </Segment>
    )
  }

  function TimeSpinner(props) {
    const { value, type, callback, step } = props

    const parseTime = (number) => {
      if (type === 'hour') {
        if (number > 23) return 0
        if (number < 0) return 24 - step
      } else if (type === 'minute') {
        if (number > 59) return 0
        if (number < 0) return 60 - step
      }
      return Math.round(number / step) * step
    }

    return (
      <div className="time-spinner">
        <Button size="big" icon onClick={(e) => callback(parseTime(parseInt(value, 10) + step))}>
          <Icon name="chevron up" />
        </Button>
        <Input value={value} />
        <Button size="big" icon onClick={(e) => callback(parseTime(parseInt(value, 10) - step))}>
          <Icon name="chevron down" />
        </Button>
      </div>
    )
  }

  function PercentSpinner(props) {
    const { value, min = 0, max = 100, callback, step = 5 } = props

    const roundToNearestStep = (x) => {
      return Math.floor(x / step) * step
    }

    const parsePercent = (number) => {
      if (number > max) return max
      if (number < min) return min
      // return number
      // // TODO: uncomment to enable 1% min
      return number === minReservedSoc ? number : roundToNearestStep(number)
    }

    return (
      <div className="percent-spinner">
        <Button size="big" icon onClick={(e) => callback(parsePercent(parseInt(value, 10) + step))}>
          <Icon name="chevron up" />
        </Button>
        <Input>
          <input value={value} />
        </Input>
        <Button size="big" icon onClick={(e) => callback(parsePercent(parseInt(value, 10) - step))}>
          <Icon name="chevron down" />
        </Button>
      </div>
    )
  }

  function Slots() {
    // still loading
    if (!epsSchedule || typeof epsSchedule[selectedDay] !== 'object') return <small>Loading...</small>

    // list slots
    if (epsSchedule[selectedDay].length)
      return (
        <React.Fragment>
          <Segment.Group style={{ marginBottom: 0 }}>
            {saving && (
              <Dimmer active inverted>
                <Loader inverted />
              </Dimmer>
            )}
            {epsSchedule[selectedDay].map((slot, index) => {
              const { id, start, state } = slot
              const isSelected = selectedSlotId === id

              return (
                <Segment
                  key={index}
                  className={`schedule-slot color ${state} ${isSelected ? 'active' : 'inactive'} ${slotConflict == start ? 'conflict' : ''}`}
                  onClick={() => {
                    setSelectedSlotId(id)
                    setSaved(false)
                    setSlotRemoved(false)
                    setErrorMessage(null)
                    setSaveFailed(false)
                    setCopiedToAllDays(false)
                    setDayCleared(false)
                  }}
                >
                  <div className="schedule">
                    <Header as="h2" className="time">
                      {moment(start, 'HH:mm:ss').format('HH:mm')}
                    </Header>
                  </div>
                  <div className="state">
                    <span>{friendlyNameFromScheduleState(state)}</span>
                  </div>
                  {isSelected && (
                    <Button
                      circular
                      icon
                      secondary
                      floated="right"
                      onClick={(e) => {
                        e.stopPropagation()
                        setSelectedSlotId(null)
                        updateScheduleObject({
                          ...epsSchedule,
                          [selectedDay]: epsSchedule[selectedDay].filter((slot) => slot.id !== id)
                        })
                        setSlotRemoved(true)
                      }}
                    >
                      <Icon name="close" />
                    </Button>
                  )}
                </Segment>
              )
            })}
          </Segment.Group>
          <NewItemButton />
        </React.Fragment>
      )

    // no slots
    if (!epsSchedule[selectedDay].length)
      return (
        <React.Fragment>
          <Message info attached="bottom">
            {getEnglishText('eps-schedule : no-schedule-set-for-day')}
          </Message>
          <NewItemButton primary />
        </React.Fragment>
      )
  }

  function EditSlot() {
    if (saveFailed) {
      // save failed
      return (
        <Segment className="schedule-detail error" placeholder>
          <Header icon>
            <Icon name="exclamation" />
            <br />
            {getEnglishText('eps-schedule : save-failed-title')}
            <Header.Subheader>
              <>{getEnglishText('eps-schedule : save-failed-body')}</>
            </Header.Subheader>
          </Header>
        </Segment>
      )
    } else if (saving) {
      // saving
      return (
        <Segment className="schedule-detail saving" placeholder>
          <Dimmer active inverted>
            <Loader inverted size="big">
              {getEnglishText('common : saving')}
            </Loader>
          </Dimmer>
        </Segment>
      )
    } else if (copiedToAllDays) {
      // just been copied to all days
      return (
        <Segment className="schedule-detail copied-to-all-days" placeholder>
          <Header icon>
            <Icon name="check" />
            {getEnglishText('eps-schedule : schedule-copied-to-all-days')}
          </Header>
        </Segment>
      )
    } else if (saved) {
      // just been saved
      return (
        <Segment className="schedule-detail saved" placeholder>
          <Header icon>
            <Icon name="check" />
            {getEnglishText('eps-schedule : schedule-saved')}
          </Header>
        </Segment>
      )
    } else if (slotRemoved) {
      // slot just been removed
      return (
        <Segment className="schedule-detail item-removed" placeholder>
          <Header icon>
            <Icon name="close" />
            {getEnglishText('eps-schedule : schedule-item-deleted')}
          </Header>
        </Segment>
      )
    } else if (dayCleared) {
      // day has just been cleared
      return (
        <Segment className="schedule-detail day-cleared" placeholder>
          <Header icon>
            <Icon name="close" />
            {`${selectedDay.substr(0, 1).toUpperCase()}${selectedDay.substr(1)}`}'s schedule cleared.
          </Header>
        </Segment>
      )
    } else if (selectedSlotId === null) {
      // no slot selected
      return (
        <Segment className="schedule-detail no-selection" placeholder>
          <Header icon>
            <Icon name="clock outline" />
            {getEnglishText('eps-schedule : select-a-schedule-item')},
            <br />
            {getEnglishText('common : or')}
          </Header>
          <Button onClick={createNewItem}>
            <Icon name="plus" /> {getEnglishText('eps-schedule : new-schedule-item')}
          </Button>
        </Segment>
      )
    }

    const { start, state, reservedSoc } = epsSchedule[selectedDay].filter((slot) => slot.id === selectedSlotId)[0]

    function updateSelectedSlot({ newState, hour, minute, reservedSoc }) {
      const [existingHour, existingMinute] = start.split(':')

      let [newHour, newMinute, newSecond] = [hour !== undefined ? hour : existingHour, minute !== undefined ? minute : existingMinute, '00']

      newHour = pad(parseInt(newHour, 10), 2)
      newMinute = pad(parseInt(newMinute, 10), 2)

      const newSlots = epsSchedule[selectedDay].map((slot) => {
        if (slot.id === selectedSlotId) {
          if (newState) {
            if (newState === 'eps-enabled') return { ...slot, state: newState, reservedSoc: minReservedSoc }
            if (newState === 'eps-disabled') return { ...slot, state: newState, reservedSoc: null }
          } else if (reservedSoc) return { ...slot, reservedSoc }
          else return { ...slot, start: [newHour, newMinute, newSecond].join(':') }
        } else {
          return slot
        }
      })

      updateScheduleObject({
        ...epsSchedule,
        [selectedDay]: newSlots
      })
    }

    // edit slot
    return (
      <Segment className="schedule-detail" placeholder>
        <Grid columns={2} stackable textAlign="center">
          <Grid.Row verticalAlign="middle">
            <Grid.Column className="state-container">
              <Header as="h2">{getEnglishText('eps-schedule : set-state')}:</Header>
              <Dropdown
                className={`color ${state}`}
                placeholder="Unit State"
                selection
                value={state}
                options={[
                  { value: 'eps-enabled', text: friendlyNameFromScheduleState('eps-enabled'), className: 'color eps-enabled' },
                  { value: 'eps-disabled', text: friendlyNameFromScheduleState('eps-disabled'), className: 'color eps-disabled' }
                ]}
                onChange={(e, data) => updateSelectedSlot({ newState: data.value })}
              />
              {state === 'eps-enabled' && (
                <div className="reserve-capacity-container">
                  <Header as="h2">{getEnglishText('eps-schedule : reserved-capacity')}</Header>
                  <PercentSpinner value={`${reservedSoc} %`} min={minReservedSoc} callback={(value) => updateSelectedSlot({ reservedSoc: value })} />
                </div>
              )}
            </Grid.Column>
            <Grid.Column className="spinner-container">
              <Header as="h2">At:</Header>
              <TimeSpinner value={moment(start, 'HH:mm:ss').format('HH')} step={1} type="hour" callback={(value) => updateSelectedSlot({ hour: value })} />
              <Header as="h2">:</Header>
              <TimeSpinner value={moment(start, 'HH:mm:ss').format('mm')} step={5} type="minute" callback={(value) => updateSelectedSlot({ minute: value })} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }

  function ButtonPanel() {
    return (
      <React.Fragment>
        <Button
          secondary
          basic
          floated="left"
          disabled={saving || !epsSchedule}
          onClick={() => {
            setSelectedSlotId(null)
            setSlotRemoved(false)
            setErrorMessage(null)
            setSaveFailed(false)
            setCopiedToAllDays(false)
            setDayCleared(true)
            updateScheduleObject({
              ...epsSchedule,
              [selectedDay]: []
            })
          }}
        >
          <span>{getEnglishText('eps-schedule : clear-day')}</span>
        </Button>

        <Button
          secondary
          basic
          floated="left"
          disabled={saving || !epsSchedule || detectConflictsInCurrentDay()}
          onClick={() => {
            if (!detectConflictsInCurrentDay()) {
              updateScheduleObject({
                monday: [...epsSchedule[selectedDay]],
                tuesday: [...epsSchedule[selectedDay]],
                wednesday: [...epsSchedule[selectedDay]],
                thursday: [...epsSchedule[selectedDay]],
                friday: [...epsSchedule[selectedDay]],
                saturday: [...epsSchedule[selectedDay]],
                sunday: [...epsSchedule[selectedDay]]
              })

              setCopiedToAllDays(true)
              setErrorMessage(null)
              setSaveFailed(false)
            }
          }}
        >
          <span>{getEnglishText('eps-schedule : copy-to-all-days')}</span>
        </Button>

        {unsavedChanges && (
          <Button primary disabled={!unsavedChanges || saving || detectConflictsInCurrentDay()} onClick={saveSchedule}>
            {getEnglishText('common : save-changes')}
          </Button>
        )}
        <Button
          secondary
          disabled={saving}
          onClick={() => {
            if (unsavedChanges) {
              setConfirmCloseModalOpen(true)
            } else {
              setMainModelOpen(false)
              if (onClose) onClose()
            }
          }}
        >
          {unsavedChanges ? getEnglishText('common : discard-changes') : getEnglishText('common : close')}
        </Button>
      </React.Fragment>
    )
  }

  function ModalContent() {
    const ticks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    return (
      <React.Fragment>
        <DayButtons />

        <div className="schedule-ribbon-container">
          <div className="schedule-ribbon-scale">
            {ticks.map((tick, index) => (
              <div key={index} className="tick" style={{ left: `${(100 / 24) * tick}%` }} />
            ))}
            <span className="start">00:00</span>
            <span className="midday">12:00</span>
            <span className="end">23:59</span>
          </div>
          <div className="schedule-ribbon">
            <EPSScheduleRibbonDay dailySchedule={epsSchedule ? epsSchedule[selectedDay] : {}} emptyFillState="eps-disabled" />
          </div>
        </div>

        <Divider hidden />

        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={6}>
              <Slots />
            </Grid.Column>
            <Grid.Column width={10}>
              <EditSlot />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <div className="schedule">
      {/* main modal */}
      <Modal id="eps-schedule-modal" open={mainModelOpen} closeOnEscape={false} closeOnDimmerClick={false} size="large" centered={false} dimmer="blurring">
        <Modal.Header>
          <Icon name="cogs" />
          &nbsp;&nbsp;&nbsp;{getEnglishText('eps-schedule : modal-title')}
          <span style={{ opacity: 0.7 }}> - {`${selectedDay.substr(0, 1).toUpperCase()}${selectedDay.substr(1)}`}</span>
        </Modal.Header>

        <Modal.Content>
          <Modal.Description>
            <ModalContent />
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          {errorMessage && (
            <Message error style={{ textAlign: 'left' }}>
              {errorMessage}
            </Message>
          )}
          <ButtonPanel />
        </Modal.Actions>
      </Modal>

      {/* confirm discard changes modal */}
      <Modal centered={false} open={confirmCloseModalOpen} size="tiny" dimmer="blurring">
        <Modal.Header>
          <Icon name="exclamation" />
          &nbsp;&nbsp;{getEnglishText('common : unsaved-changes')}
        </Modal.Header>
        <Modal.Content>{getEnglishText('eps-schedule : schedule-has-unsaved-changes')}</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content={getEnglishText('common : save-changes')}
            disabled={slotConflict}
            onClick={() => {
              saveSchedule()
              setConfirmCloseModalOpen(false)
            }}
          />
          <Button
            secondary
            content={getEnglishText('common : discard-changes')}
            onClick={() => {
              setConfirmCloseModalOpen(false)
              setMainModelOpen(false)
              if (onClose) onClose()
            }}
          />
          <Button secondary basic content={getEnglishText('common : cancel')} onClick={() => setConfirmCloseModalOpen(false)} />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export { EPSScheduleEditor }
