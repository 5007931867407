/*
  Model Code Parser
  15th November 2024
  james.lowe@powervault.co.uk
*/

function parseModelCode(model) {
  const p3ModelRegex = /^PV3-(01|02|03)-\d{2}-\d{2}([DE]*){0,1}$/
  const pvlModelRegex = /^PVL-(04|05|10)-\d{2}-\d{2}-\d{2}([ESD]*){0,1}$/
  const p4ModelRegex = /^PV4-(04|05)-\d{2}-\d{2}-\d{2}([EWD]*){0,1}$/
  const p5ModelRegex = /^PV5-(20)-\d{2}-\d{2}-\d{2}-\d{2}([D]*){0,1}$/

  if (!model || !model.length) return false

  if (p3ModelRegex.test(model)) {
    return parseP3ModelCode(model)
  } else if (pvlModelRegex.test(model)) {
    return parsePVCompactModelCode(model)
  } else if (p4ModelRegex.test(model)) {
    return parseP4ModelCode(model)
  } else if (p5ModelRegex.test(model)) {
    return parseP5ModelCode(model)
  } else {
    return false
  }
}

function parseP3ModelCode(model) {
  const product = 'Powervault'
  const inverterPower = 3.6
  let productLine = ''
  let [productCode, batteryType, batteryCapacity, batteryModules] = model.split('-')
  let batteryTypeStaff = null
  let productLineStaff = null
  let dcConnected = false
  let epsAbility = false
  let accurateSoc = false
  let modifiedSoCRange = false
  const consoleHasLoginSystem = false
  const giftSmartStor = true
  const hasLedSettings = false
  const hasWifiHotspot = false
  const displaySocOnChart = false
  const onePercentEpsReservedSoc = false
  let vocSensorFitted = false

  if (batteryModules.includes('D')) {
    dcConnected = true
    batteryModules = batteryModules.replace('D', '')
  }
  if (batteryModules.includes('E')) {
    epsAbility = true
    batteryModules = batteryModules.replace('E', '')
  }

  if (batteryType === '01') {
    batteryTypeStaff = 'Amita'
    batteryType = 'Amita'
    productLineStaff = 'P3'
    productLine = 'P3'
    modifiedSoCRange = true
    vocSensorFitted = true
  }
  if (batteryType === '02') {
    batteryTypeStaff = 'Eco'
    batteryType = 'Eco'
    productLineStaff = 'P3 Eco'
    productLine = 'P3 Eco'
    vocSensorFitted = false
  }
  if (batteryType === '03' || batteryType === '04') {
    batteryTypeStaff = 'Pylontech'
    batteryType = 'Lithium Ion'
    productLineStaff = 'P3X'
    productLine = 'P3'
    accurateSoc = true
    vocSensorFitted = false
  }

  batteryModules = parseInt(batteryModules, 10)
  batteryCapacity = parseInt(batteryCapacity, 10)

  let productName = `${product} ${productLine} ${batteryCapacity} kWh - ${batteryType}${dcConnected ? ', DC Connected' : ''}${epsAbility ? ', EPS' : ''}`

  return {
    model,
    productCode,
    productName,
    productLine,
    productLineStaff,
    batteryType,
    batteryTypeStaff,
    batteryCapacity,
    batteryModules,
    inverterPower,
    dcConnected,
    epsAbility,
    accurateSoc,
    modifiedSoCRange,
    consoleHasLoginSystem,
    giftSmartStor,
    hasLedSettings,
    hasWifiHotspot,
    displaySocOnChart,
    onePercentEpsReservedSoc,
    vocSensorFitted
  }
}

function parsePVCompactModelCode(model) {
  const product = 'Powervault'
  const productLine = 'PVCompact'
  const inverterPower = 3.6
  let [productCode, batteryType, batteryCapacity, caseSize, paintFinish] = model.split('-')
  let epsAbility = false
  // let solarConnected = false
  let dcConnected = false
  let batteryTypeStaff = null
  let paintFinishTop = null
  let paintFinishBottom = null
  const accurateSoc = true
  const modifiedSoCRange = false
  const consoleHasLoginSystem = true
  const giftSmartStor = true
  const hasLedSettings = true
  let singleBatteryModuleCapacity = null
  let numberOfBatteryModules = null
  const hasWifiHotspot = false
  const displaySocOnChart = false
  const onePercentEpsReservedSoc = false
  const vocSensorFitted = false

  if (paintFinish.includes('E')) {
    epsAbility = true
    paintFinish = paintFinish.replace('E', '')
  }
  // if (paintFinish.includes('S')) {
  //   solarConnected = true
  //   paintFinish = paintFinish.replace('S', '')
  // }
  if (paintFinish.includes('D')) {
    dcConnected = true
    paintFinish = paintFinish.replace('D', '')
  }

  batteryType = parseInt(batteryType, 10)
  if (batteryType === 4) {
    batteryType = 'Lithium Ion'
    batteryTypeStaff = 'Pylontech, US2000C'
    singleBatteryModuleCapacity = 2
  }
  if (batteryType === 5) {
    batteryType = 'Lithium Ion'
    batteryTypeStaff = 'Pylontech, US3000C'
    singleBatteryModuleCapacity = 3
  }
  if (batteryType === 10) {
    batteryType = 'Lithium Ion'
    batteryTypeStaff = 'Dyness, B4850'
    singleBatteryModuleCapacity = 2
  }

  caseSize = parseInt(caseSize, 10)
  if (caseSize === 1) caseSize = 'Small'
  if (caseSize === 2) caseSize = 'Large'

  batteryCapacity = parseInt(batteryCapacity, 10)
  numberOfBatteryModules = batteryCapacity / singleBatteryModuleCapacity

  if (paintFinish[0] == 1) paintFinishTop = 'White'
  if (paintFinish[0] == 2) paintFinishTop = 'Grey'
  if (paintFinish[0] == 9) paintFinishTop = 'Custom'
  if (paintFinish[1] == 1) paintFinishBottom = 'White'
  if (paintFinish[1] == 2) paintFinishBottom = 'Grey'
  if (paintFinish[1] == 9) paintFinishBottom = 'Custom'

  let productName = `${product} ${productLine} ${batteryCapacity} kWh - ${batteryType}, ${caseSize}${epsAbility ? ', EPS' : ''}${
    dcConnected ? ', DC Connected' : ''
  }`

  return {
    model,
    productCode,
    productName,
    productLine,
    productLineStaff: productLine,
    batteryType,
    batteryTypeStaff,
    batteryCapacity,
    inverterPower,
    singleBatteryModuleCapacity,
    numberOfBatteryModules,
    caseSize,
    epsAbility,
    // solarConnected,
    dcConnected,
    paintFinishTop,
    paintFinishBottom,
    accurateSoc,
    modifiedSoCRange,
    consoleHasLoginSystem,
    giftSmartStor,
    hasLedSettings,
    hasWifiHotspot,
    displaySocOnChart,
    onePercentEpsReservedSoc,
    vocSensorFitted
  }
}

function parseP4ModelCode(model) {
  const product = 'Powervault'
  const productLine = 'P4'
  const inverterPower = 6
  let [productCode, batteryType, batteryCapacity, caseSize, paintFinish] = model.split('-')
  let epsAbility = false
  // let wholeHouseEps = false
  let dcConnected = false
  let batteryTypeStaff = null
  let paintFinishTop = null
  let paintFinishBottom = null
  const accurateSoc = true
  const modifiedSoCRange = false
  const consoleHasLoginSystem = true
  const giftSmartStor = true
  const hasLedSettings = true
  let singleBatteryModuleCapacity = null
  let numberOfBatteryModules = null
  const hasWifiHotspot = false
  const displaySocOnChart = false
  const onePercentEpsReservedSoc = true
  const vocSensorFitted = false

  if (paintFinish.includes('E')) {
    epsAbility = true
    paintFinish = paintFinish.replace('E', '')
  }
  // if (paintFinish.includes('W')) {
  //   wholeHouseEps = true
  //   paintFinish = paintFinish.replace('W', '')
  // }
  if (paintFinish.includes('D')) {
    dcConnected = true
    paintFinish = paintFinish.replace('D', '')
  }

  batteryType = parseInt(batteryType, 10)
  if (batteryType === 4) {
    batteryType = 'Lithium Ion'
    batteryTypeStaff = 'Pylontech, US2000C'
    singleBatteryModuleCapacity = 2
  }
  if (batteryType === 5) {
    batteryType = 'Lithium Ion'
    batteryTypeStaff = 'Pylontech, US3000C'
    singleBatteryModuleCapacity = 3
  }

  const sizeColumns = parseInt(caseSize[0], 10)
  const sizeRows = parseInt(caseSize[1], 10)

  switch (sizeColumns) {
    case 1:
      caseSize = 'Slim'
      break
    case 2:
      caseSize = 'Wide'
      break
  }
  switch (sizeRows) {
    case 1:
      caseSize += ', Small'
      break
    case 2:
      caseSize += ', Medium'
      break
    case 3:
      caseSize += ', Large'
      break
  }

  // caseSize = parseInt(caseSize, 10)

  // if (caseSize === 1) caseSize = 'Small'
  // if (caseSize === 2) caseSize = 'Large'

  batteryCapacity = parseInt(batteryCapacity, 10)
  numberOfBatteryModules = batteryCapacity / singleBatteryModuleCapacity

  if (paintFinish[0] == 1) paintFinishTop = 'White'
  if (paintFinish[0] == 2) paintFinishTop = 'Grey'
  if (paintFinish[0] == 9) paintFinishTop = 'Custom'
  if (paintFinish[1] == 1) paintFinishBottom = 'White'
  if (paintFinish[1] == 2) paintFinishBottom = 'Grey'
  if (paintFinish[1] == 9) paintFinishBottom = 'Custom'

  let productName = `${product} ${productLine} ${batteryCapacity} kWh - ${batteryType}, ${caseSize}${epsAbility ? ', EPS' : ''}${
    dcConnected ? ', DC Connected' : ''
  }`

  return {
    model,
    productCode,
    productName,
    productLine,
    productLineStaff: productLine,
    batteryType,
    batteryTypeStaff,
    batteryCapacity,
    inverterPower,
    singleBatteryModuleCapacity,
    numberOfBatteryModules,
    caseSize,
    epsAbility,
    // wholeHouseEps,
    dcConnected,
    paintFinishTop,
    paintFinishBottom,
    accurateSoc,
    modifiedSoCRange,
    consoleHasLoginSystem,
    giftSmartStor,
    hasLedSettings,
    hasWifiHotspot,
    displaySocOnChart,
    onePercentEpsReservedSoc,
    vocSensorFitted
  }
}

function parseP5ModelCode(model) {
  const product = 'Powervault'
  const productLine = 'P5'
  let [productCode, batteryType, batteryCapacity, inverterPower, paintFinish, smartBridgePaintFinish] = model.split('-')
  const epsAbility = true
  let dcConnected = false
  let batteryTypeStaff = null
  const accurateSoc = true
  const modifiedSoCRange = false
  const consoleHasLoginSystem = true
  const giftSmartStor = false
  const hasLedSettings = true
  let singleBatteryModuleCapacity = 5
  let numberOfBatteryModules = null
  const hasWifiHotspot = true
  const displaySocOnChart = false
  const onePercentEpsReservedSoc = true
  const vocSensorFitted = false

  if (smartBridgePaintFinish.includes('D')) {
    dcConnected = true
    smartBridgePaintFinish = smartBridgePaintFinish.replace('D', '')
  }

  batteryType = parseInt(batteryType, 10)
  if (batteryType === 20) {
    batteryType = 'Lithium Ion'
    batteryTypeStaff = paintFinish === '01' ? 'PV506A' : 'PV505A'
  }

  batteryCapacity = parseInt(batteryCapacity, 10)
  numberOfBatteryModules = batteryCapacity / singleBatteryModuleCapacity

  if (inverterPower === '03') inverterPower = 3.6
  if (inverterPower === '06') inverterPower = 6

  if (paintFinish == '01') paintFinish = 'White'
  if (paintFinish == '02') paintFinish = 'Grey'

  if (smartBridgePaintFinish == '01') smartBridgePaintFinish = 'White'
  if (smartBridgePaintFinish == '02') smartBridgePaintFinish = 'Grey'

  let productName = `${product} ${productLine} ${batteryCapacity} kWh - ${batteryType}${dcConnected ? ', DC Connected' : ''}`

  return {
    model,
    productCode,
    productName,
    productLine,
    productLineStaff: productLine,
    batteryType,
    batteryTypeStaff,
    batteryCapacity,
    inverterPower,
    singleBatteryModuleCapacity,
    numberOfBatteryModules,
    epsAbility,
    paintFinish,
    smartBridgePaintFinish,
    dcConnected,
    accurateSoc,
    modifiedSoCRange,
    consoleHasLoginSystem,
    giftSmartStor,
    hasLedSettings,
    hasWifiHotspot,
    displaySocOnChart,
    onePercentEpsReservedSoc,
    vocSensorFitted
  }
}

export { parseModelCode }
// module.exports = { parseModelCode }
