import pckg from '../../../package.json'
import React, { useEffect, useState } from 'react'
import { Loader, Message, Button } from 'semantic-ui-react'
import { cleanup } from '../../common'
import { getEnglishText } from '../../dictionary'

import './InitialLoader.css'
import animatedLogo from '../../assets/powervault-animated-logo.gif'
import staticLogo from '../../assets/powervault-animated-logo.png'

let timeoutHandle = null
const slightlyTooLongDuration = 5 * 1000 // 5 seconds
const wayTooLongDuration = 10 * 1000 // 10 seconds
const failedToLoadDuration = 20 * 1000 // 20 seconds

const SLIGHTLY_TOO_LONG = 2
const WAY_TOO_LONG = 3
const FAILED_TO_LOAD = 4

function InitialLoader({ loading, stage }) {
  const [displayLoader, setDisplayLoader] = useState(true)
  const [displayStaticLogo, setDisplayStaticLogo] = useState(false)
  const [takingTooLong, setTakingTooLong] = useState(false)
  const { version } = pckg

  useEffect(() => {
    // when loader is rendered, start timer to check if loading takes too long
    timeoutHandle = setTimeout(() => {
      setTakingTooLong(SLIGHTLY_TOO_LONG)
      timeoutHandle = setTimeout(() => {
        setTakingTooLong(WAY_TOO_LONG)
        timeoutHandle = setTimeout(() => {
          setTakingTooLong(FAILED_TO_LOAD)
        }, failedToLoadDuration)
      }, wayTooLongDuration)
    }, slightlyTooLongDuration)
    return () => clearTimeout(timeoutHandle)
  }, [])

  useEffect(() => {
    if (loading) {
      // repalce gif with static PNG after 3 seconds
      setTimeout(() => {
        setDisplayStaticLogo(true)
      }, 3000)
    } else {
      // hide loading screen 1 second after Home component reports everything has loaded
      setTimeout(() => {
        document.body.classList.remove('initial-load')
        setDisplayLoader(false)
      }, 1000)
    }
  }, [loading])

  function parseTakingTooLong() {
    switch (takingTooLong) {
      case SLIGHTLY_TOO_LONG:
        return { text: getEnglishText('loader : taking-longer-than-usual'), class: 'orange' }
      case WAY_TOO_LONG:
        return { text: getEnglishText('loader : something-is-wrong'), class: 'red' }
      case FAILED_TO_LOAD:
        return { text: getEnglishText('loader : failed-to-load'), class: 'red' }
      default:
        return { text: getEnglishText('loader : loading'), class: null }
    }
  }

  return (
    <div className={`InitialLoader ${displayLoader ? 'active' : 'inactive'}`}>
      <div className="container">
        <img src={displayStaticLogo ? staticLogo : animatedLogo} alt="Powervault Logo" />
        <span className="sub-header fade-in">{getEnglishText('common : app-title')}</span>
        {takingTooLong === FAILED_TO_LOAD ? (
          <div className="failed-to-load fade-in">
            <Message error>
              <Message.Header>{getEnglishText('loader : failed-to-load')}</Message.Header>
              <Message.Content>
                <br />
                <Button
                  primary
                  icon="chevron right"
                  labelPosition="right"
                  content={getEnglishText('loader : reload-button')}
                  onClick={async () => await cleanup({ clearCache: true, reload: true, initiator: 'InitialLoader' })}
                />
                <p style={{ paddingTop: 10 }}>
                  If the problem persists after trying the button above please{' '}
                  <strong>
                    <a href={`mailto:service@powervault.co.uk?subject=Portal%20failed%20to%20load%20(v${version})`}>email us</a>
                  </strong>
                </p>
              </Message.Content>
            </Message>
          </div>
        ) : (
          <div className="loader-container fade-in-delay">
            <Loader inline active size="small" />
            <small className={`loading-text ${parseTakingTooLong().class}`}>{takingTooLong ? parseTakingTooLong().text : stage}</small>
          </div>
        )}
      </div>
    </div>
  )
}

export { InitialLoader }
